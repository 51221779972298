import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Messages } from "primereact/messages";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";
import ImageGalleryCategory from "../../../ImageGalleryCategory";

function EditCategory(props) {
  const msgs = useRef(null);
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);
  const [foodCategory, setFoodCategory] = useState(
    localStorage.getItem("foodCategory")
  );
  const [filename, setFilename] = useState(localStorage.getItem("imageNameCategory"));
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };
  const [id, setId] = useState(localStorage.getItem("foodCategoryId"));

  const editCategory = () => {
    console.log("the new updated food category is ", foodCategory);
    
    

    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/category/${id}`;

    const dataToUpdate = {
      foodCategory: foodCategory,
      filename:localStorage.getItem("imageNameCategory"),
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Category updated successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error updating category",
            error,
            closable: false,
          },
        ]);
      });
  };
  return (
    <div>
    <div className="messageContainer">
      <div className="card flex justify-content-center">
        <Messages ref={msgs} />
      </div>
    </div>
    <div maxWidth="sm" style={{ textAlign: "center" }}>
        <div style={{ textAlign: "center" }}>
          <img
            alt="Customer"
            src={require(`../../../categoryUploadImages/${localStorage.getItem("imageNameCategory")}`)}
            style={{
              width: "70%",
              height: "200px",
              borderRadius: 8,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              marginBottom: 20,
            }}
          />
        </div>
      </div>
      <form className="form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Category"
          name="category"
          value={foodCategory}
          autoComplete="category"
          onChange={(e) => setFoodCategory(e.target.value)}
        /><Grid item xs={12} md={12}>
        <div className="card">
            <Button
              variant="contained"
              style={{ width: "100%",marginBottom:"20px" }}
              endIcon={<AddAPhotoIcon />}
              onClick={()=>setImageGalleryVisibility(true)}
            >
              Upload Image
            </Button>
          {selectedFile && (
            <div>
              <p>Selected File: {selectedFile.name}</p>
            </div>
          )}
        </div>
      </Grid>
        <Button
          variant="contained"
          color="success"
          className="submit"
          onClick={editCategory}
        >
          Save Changes
        </Button>
      </form>
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "50vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGalleryCategory />
      </Dialog>
    </div>
  );
}

export default EditCategory;
