import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { Messages } from "primereact/messages";

function ProcessingStep(props) {
  const [dispatchRider, setDispatchRider] = useState("");
  const msgs = useRef(null);
  const [dispatchRiders, setDispatchRiders] = useState([]);
  const [id, setId] = useState(localStorage.getItem("orderId"));

  const fetchDispatchRiders = () => {
    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/riders/${localStorage.getItem("foodDeliveryCompanyId")}`)
      .then((response) => {
        setDispatchRiders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchDispatchRiders();
  }, []);

  const shipOrder = () => {
    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/shipOrder/${id}`;

    const dataToUpdate = {
      foodDeliveryDispatchRider: dispatchRider,
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        props.fetchOrderDetails();
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Order shipped successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error shipping order",
            error,
            closable: false,
          },
        ]);
      });
  };

  return (
    <div>
      <div className="card flex justify-content-center">
        <Messages ref={msgs} />
      </div>
      <h1>Processing Item</h1>
      <div>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "1rem" }}
        >
          <InputLabel>Dispatch Riders</InputLabel>
          <Select
            value={dispatchRider}
            onChange={(e) => setDispatchRider(e.target.value)}
            label="Dispatch Riders"
          >
            {dispatchRiders.map((rider) => (
              <MenuItem key={rider} value={rider}>
                <Avatar
                  alt="Customer Image"
                  src={`../../../uploadImages/${rider.filename}`}
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                  }}
                />{" "}
                {rider.riderName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Button
        label="Ready to ship"
        icon="pi pi-shopping-cart"
        size="small"
        severity="success"
        onClick={shipOrder}
        style={{ width: "100%", marginTop: "10px" }}
      />
    </div>
  );
}

export default ProcessingStep;
