import React from "react";
import Container from "./Container";
import Orders from "./order/Orders";
import { Box } from "@mui/material";

function Orders1() {
  return (
    <div>
      <Container>
         <Orders />
      </Container>
    </div>
  );
}

export default Orders1;
