import { Grid, Tooltip, Typography } from "@mui/material";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Person, Phone, Refresh } from "@mui/icons-material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import React, { useState, useEffect, useRef } from "react";
import axios, { Axios } from "axios";
import { Messages } from "primereact/messages";
import AddDispatchRider from "./AddDispatchRider";
import Container from "../Container";
import { Dialog } from "primereact/dialog";
import EditDispatchRider from "./EditDispatchRider";
import { Badge } from "primereact/badge";

function DispatchRiders() {
  const msgs = useRef(null);
  const [dispatchRiders, setDispatchRiders] = useState([]);
  const [editRiderDialog, setEditRiderDialog] = useState(false);

  const navigate = useNavigate();

  const moveToRiderTrips = (id, riderName, riderFilename) => {
    localStorage.setItem("riderId", id);
    localStorage.setItem("riderName", riderName);
    localStorage.setItem("riderFilename", riderFilename);
    console.log("the rider id is ", localStorage.getItem("riderId"));
    navigate("/riderTrips");
  };

  const editRiders = (
    id,
    riderName,
    riderTelephone,
    riderNumber,
    riderColor,
    riderFilename
  ) => {
    localStorage.setItem("riderId", id);
    localStorage.setItem("riderName", riderName);
    localStorage.setItem("riderTelephone", riderTelephone);
    localStorage.setItem("riderNumber", riderNumber);
    localStorage.setItem("riderColor", riderColor);
    localStorage.setItem("imageName", riderFilename);
    setEditRiderDialog(true);
  };

  const fetchDispatchRiders = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/riders/${localStorage.getItem(
          "foodDeliveryCompanyId"
        )}`
      )
      .then((response) => {
        setDispatchRiders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchDispatchRiders();
  }, []);

  const deleteDispatchRider = (id) => {
    axios
      .delete(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/rider/${id}`)
      .then((response) => {
        if (response.status >= 204) {
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Rider deleted successfully.",
              closable: false,
            },
          ]);

          fetchDispatchRiders();
        } else {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Failed to delete rider.",
              closable: false,
            },
          ]);
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  return (
    <div>
      <Container>
        <div className="dispatchRiderContainer">
          <div className="dispatchRiderColumn1">
            <div className="messageContainer">
              <div className="card flex justify-content-center">
                <Messages ref={msgs} />
              </div>
            </div>
            <Grid item xs={8}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="p" style={{ margin: "10px" }}>
                  Dispatch Riders
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={dispatchRiders.length}
                    severity="success"
                  ></Badge>
                </Typography>
              </div>
            </Grid>
            {dispatchRiders.map((rider) => (
              <div className="dispatchRiderColumn1Box" key={rider.id}>
                <div className="dispatchRiderColumn1BoxCol1">
                  <img
                    alt="Customer"
                    src={require(`../../../uploadImages/${rider.filename}`)}
                    style={{
                      width: "100%",
                      height: "125px",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <div className="dispatchRiderColumn1BoxCol2">
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      <Person
                        style={{ fontSize: "12px", marginRight: "10px", marginLeft: "10px",color:"gray" }}
                      />
                      {rider.riderName}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "12px" }}>
                      <Phone
                        style={{ fontSize: "12px", marginRight: "10px", marginLeft: "10px",color:"gray" }}
                      />
                      {rider.telephone}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      <DirectionsCarIcon
                        style={{ fontSize: "12px", marginRight: "10px", marginLeft: "10px",color:"gray" }}
                      />
                      {rider.motorcycleNumber}
                    </span>
                  </div>
                  <div>
                    <div style={{ fontSize: "12px", display: "flex" }}>
                      {" "}
                      <div>
                        <LocalShippingIcon
                        style={{ fontSize: "12px", marginRight: "10px", marginLeft: "10px",color:"gray" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "20%",
                          height: "10px",
                          borderRadius: "2px",
                          backgroundColor: rider.motorcycleColor,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div>
                    <Tooltip title={`Edit ${rider.riderName} Account`}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        icon="pi pi-pencil"
                        style={{
                          backgroundColor: "whitesmoke",
                          fontSize: "5px",
                          border: "none",
                          color: "green",
                        }}
                        onClick={() =>
                          editRiders(
                            rider.id,
                            rider.riderName,
                            rider.telephone,
                            rider.motorcycleNumber,
                            rider.motorcycleColor,
                            rider.filename
                          )
                        }
                      />
                    </Tooltip>
                    <span style={{ margin: "5px" }}></span>

                    <Tooltip title={`Delete ${rider.riderName} Account`}>
                      <Button
                        variant="contained"
                        icon="pi pi-trash"
                        size="small"
                        onClick={() => deleteDispatchRider(rider.id)}
                        style={{
                          backgroundColor: "whitesmoke",
                          fontSize: "5px",
                          border: "none",
                          color: "green",
                        }}
                      />
                    </Tooltip>
                    <span style={{ margin: "5px" }}></span>
                    <Tooltip title={`View ${rider.riderName} Trips`}>
                      <Button
                        size="small"
                        icon="pi pi-eye"
                        variant="contained"
                        style={{
                          backgroundColor: "whitesmoke",
                          fontSize: "5px",
                          border: "none",
                          color: "green",
                        }}
                        onClick={() =>
                          moveToRiderTrips(
                            rider.id,
                            rider.riderName,
                            rider.filename
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="dispatchRiderColumn2">
            <AddDispatchRider fetchDispatchRiders={fetchDispatchRiders} />
          </div>
        </div>
        <Dialog
          header="Edit Rider"
          visible={editRiderDialog}
          style={{ width: "50vw" }}
          onHide={() => setEditRiderDialog(false)}
        >
          <EditDispatchRider fetchDispatchRiders={fetchDispatchRiders} />
        </Dialog>
      </Container>
    </div>
  );
}

export default DispatchRiders;
