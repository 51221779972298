import React, { useRef, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import axios from "axios";
import { Messages } from "primereact/messages";

function OrderStep(props) {
  const msgs = useRef(null);
  const [id, setId] = useState(localStorage.getItem("orderId"));

  const acceptOrder = () => {
    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/acceptOrder/${id}`;

    const dataToUpdate = {
      id: id,
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        props.fetchOrderDetails();
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Order accepted successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error accepting order",
            error,
            closable: false,
          },
        ]);
      });
  };
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <div>
        {/** <form className="flex flex-column gap-2">
          <div>
            <label htmlFor="description">Message</label>
          </div>
          <InputTextarea
            inputid="description"
            name="description"
            rows={4}
            cols={30}
            required="true"
            style={{ width: "100%" }}
          />
          <Button
            label="Reject order"
            type="submit"
            icon="pi pi-times"
            severity="danger"
            size="small"
            style={{ width: "100%" }}
          />
        </form>*/}
        <Button
          label="Accept order"
          icon="pi pi-check"
          size="small"
          severity="success"
          onClick={acceptOrder}
          style={{ width: "100%", marginTop: "10px" }}
        />
      </div>
    </div>
  );
}

export default OrderStep;
