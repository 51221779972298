import Container from "../Container";
import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton,
  Rating,
  Grid,
} from "@mui/material";
import { FileUpload } from "primereact/fileupload";
import { AddCircleRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Menus from "./Menus";
import axios from "axios";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";

function EditMenu(props) {
  const msgs = useRef(null);
  const navigate = useNavigate();
  const [id, setId] = useState(localStorage.getItem("menuId"));
  const [foodName, setFoodName] = useState(localStorage.getItem("foodName"));
  const [foodDescription, setFoodDescription] = useState(
    localStorage.getItem("foodDescription")
  );
  const [review, setReview] = useState(localStorage.getItem("review"));
  const [foodDeliveryCategory, setFoodDeliveryCategory] = useState(
    localStorage.getItem("foodCategory")
  );
  const [status, setStatus] = useState(localStorage.getItem("status"));
  const [price, setPrice] = useState(localStorage.getItem("price"));
  const [filename, setFilename] = useState(localStorage.getItem("imageName"));
  const [foodCategories, setFoodCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  console.log(localStorage.getItem("foodCategory"));
  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };
  const [loading, setLoading] = useState(false);
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);

  useEffect(() => {
    fetch(
      `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/categories/${localStorage.getItem(
        "foodDeliveryCompanyId"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setFoodCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching food categories:", error);
      });
  }, []);
  const updateMenu = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await handleUpdateMenu();
    } catch (error) {
      // Handle errors here
    }

    setLoading(false);
  };

  const handleUpdateMenu = async () => {
    console.log(id);
    console.log(foodName);
    console.log(foodDescription);
    console.log(foodDeliveryCategory);
    console.log(review);
    console.log(status);
    console.log(price);
    console.log(filename);

    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menu/${id}`;

    const formData = {
      foodName: foodName,
      foodDescription: foodDescription,
      //foodDeliveryCategory: foodDeliveryCategory,
      review: review,
      status: status,
      price: price,
      filename: localStorage.getItem("imageName"),
    };
    const response = await axios.put(
      `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menu/${id}`,
      formData
    );
    if (response.status >= 200) {
      fetchMenus();
      msgs.current.show([
        {
          sticky: false,
          severity: "success",
          summary: "Successs",
          detail: "Menu updated successfully",
          closable: false,
        },
      ]);
    } else {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Error updating menu",
          closable: false,
        },
      ]);
    }
  };

  const fetchMenus = () => {
    props.fetchMenus();
  };
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <div maxWidth="sm" style={{ textAlign: "center" }}>
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center", width: "50%" }}>
            <img
              alt="Customer"
              src={require(`../../../uploadImages/${localStorage.getItem(
                "imageName"
              )}`)}
              style={{
                width: "98%",
                borderRadius: 8,
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: 20,
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Item Name"
                  variant="outlined"
                  fullWidth
                  value={foodName}
                  onChange={(e) => setFoodName(e.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Price"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "1rem" }}
                >
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={foodDeliveryCategory}
                    onChange={(e) => setFoodDeliveryCategory(e.target.value)}
                    label="Category"
                  >
                    {foodCategories.map((category) => (
                      <MenuItem key={category} value={category} style={{color:"red"}}>
                        {category.foodDeliveryCategory}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "1rem" }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="in stock">IN STOCK</MenuItem>
                    <MenuItem value="out of stock">OUT OF STOCK</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={foodDescription}
                  onChange={(e) => setFoodDescription(e.target.value)}
                  style={{ marginBottom: "1rem" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <span style={{ fontSize: "15px" }}>
                    <h3>Rating Star</h3>
                    <Rating
                      name="read-only"
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                  </span>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="card">
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    endIcon={<AddAPhotoIcon />}
                    onClick={() => setImageGalleryVisibility(true)}
                  >
                    Upload Image
                  </Button>
                  {selectedFile && (
                    <div>
                      <p>Selected File: {selectedFile.name}</p>
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                fullWidth
                  variant="contained"
                  color="success"
                  onClick={updateMenu}
                >
                  {loading ? (
                    <div
                      className="card"
                      style={{ position: "absolute", zIndex: "99" }}
                    >
                      {" "}
                      <ProgressSpinner
                        style={{ width: "50px", height: "50px" }}
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "70vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGallery />
      </Dialog>
    </div>
  );
}

export default EditMenu;
