import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog as HeadlessUIDialog } from "@headlessui/react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { AppBar, Toolbar } from "@mui/material";
import Badge from "@mui/material/Badge";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { Avatar } from "primereact/avatar";
import logo from "../../images/ccLogo.png";
import { Tooltip } from "@mui/material";
import {
  FaHome,
  FaBox,
  FaSearch,
  FaShoppingCart,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Header() {
  {
    /** WEBSITE NAVIGATION LIST */
  }

  const navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);
  const [visible, setVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const fetchAllMenusFunction = () => {
    localStorage.setItem("source", "products");
    navigate("/allProducts");
  };
  const addCustomer = () => {
    const url = "/addCustomer";
    window.open(url, "_blank");
  };
  const appBarStyle = {
    backgroundColor: "#fff",
    //backgroundColor:"green",
    transition: "background-color 0.3s",
    boxShadow: " 0px 3px 6px rgba(238, 5, 149, 0.288)",
    height: "60px",
    color: " #ff007f",
  };
  const menuStyle = {
    color: scrolling ? "black" : "#fff",
  };
  const menuIconStyle = {
    color: scrolling ? "#ff007f" : "#fff",
  };
  const logoStyle = {
    width: "70px",
    position: "absolute",
    marginTop: "-5px",
    cursor: "pointer",
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigation = [
    { name: "Home", href: "/", icon: <FaHome /> },
    { name: "Products", href: "/allProducts", icon: <FaBox /> },
    { name: "Search", href: "/search", icon: <FaSearch /> },
    { name: "Orders", href: "/customerOrders", icon: <FaShoppingCart /> },
    { name: "Contact", href: "/contact-us", icon: <FaPhone /> },
  ];

  const logoTextStyle = {
    color: "black",
    marginLeft: "45px",
    fontSize: "15px",
    fontWeight: "bold",
    fontFamily: "monospace",
    cursor: "pointer",
  };
  const hideIcons = {
    display: "block",
    marginLeft: "10px",
    cursor: "pointer",
  };
  return (
    <div>
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <header className="absolute inset-x-0 top-0 z-50 appBar">
            <div className="flex lg:flex-1">
              <a className="-m-1.5 p-1.5">
                <img
                  src={require("../../images/ccLogo.png")}
                  className="App-logo"
                  style={logoStyle}
                  onClick={() => navigate("/")}
                  alt="Image"
                />
              </a>
            </div>
            <nav
              className="flex items-center justify-between p-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex lg:flex-1" style={{ marginTop: "-10px" }}>
                <a className="-m-1.5 p-1.5">
                  {/** <h1>Your Logo</h1>*/}
                  <h1 style={logoTextStyle} onClick={() => navigate("/")}>
                    Count Club Wholesale
                  </h1>
                </a>
                <div className="iconContainer" style={hideIcons}>
                  <Facebook
                    className="menu"
                    onClick={() => {
                      window.open(
                        "https://web.facebook.com/profile.php?id=100088279560422",
                        "_blank"
                      );
                    }}
                    style={{ color: "#1877F2", fontSize: "15px" }}
                    data-aos="fade-up"
                  />{" "}
                  <Instagram
                    className="menu"
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/countclubwholesale_gh/",
                        "_blank"
                      );
                    }}
                    style={{ color: "orangered", fontSize: "15px" }}
                    data-aos="fade-up"
                  />{" "}
                  <WhatsApp
                    className="menu"
                    onClick={() => {
                      window.open("https://wa.me/+233550270851", "_blank");
                    }}
                    style={{ color: "#25D366", fontSize: "15px" }}
                    data-aos="fade-up"
                  />
                </div>
              </div>
              <div className="flex lg:hidden">
                <Tooltip title="My Account">
                  {" "}
                  <Avatar
                    icon="pi pi-user"
                    onClick={() => navigate("/userAccount")}
                    style={{
                      backgroundColor: "gold",
                      marginRight: "-9px",
                      color: "#ffffff",
                      marginTop: "-10px",
                      boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.1)",
                    }}
                    shape="circle"
                  />
                </Tooltip>

                {/*<button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <FormatAlignRightIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    style={menuIconStyle}
                  />
                </button>*/}
              </div>
              <div
                className="hidden lg:flex lg:gap-x-12"
                style={{ marginTop: "-13px" }}
              >
                <ul className="hidden lg:flex lg:gap-x-12">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm font-semibold leading-6 menu myTitle"
                      >
                        {item.icon}
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="hidden lg:flex lg:flex-1 lg:justify-end"
                style={{ cursor: "pointer" }}
              >
                {/** <Badge
                  color="success" // You can customize the color
                  badgeContent={cartItemCount}
                  onClick={() => setVisible(true)}
                >
                  <ShoppingCartIcon onClick={() => setVisible(true)} />
                </Badge> */}
                <Tooltip title="My Account">
                  {" "}
                  <Avatar
                    icon="pi pi-user"
                    onClick={() => navigate("/userAccount")}
                    style={{
                      backgroundColor: "gold",
                      marginRight: "-9px",
                      color: "#ffffff",
                      marginTop: "-5px",
                      boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.1)",
                    }}
                    shape="circle"
                  />
                </Tooltip>
              </div>
            </nav>
            <HeadlessUIDialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <HeadlessUIDialog.Panel
                style={{ zIndex: "9999999" }}
                className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10  animate__animated animate__fadeInRight animated-text"
              >
                <div className="flex items-center justify-between">
                  <div style={{ marginBottom: "35px" }}>
                    <a className="-m-1.5 p-1.5">
                      <img
                        src={require("../../images/ccLogo.png")}
                        className="App-logo"
                        style={logoStyle}
                        onClick={() => navigate("/")}
                        alt="Image"
                      />
                    </a>
                  </div>

                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 menu"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={addCustomer}
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 menu"
                      >
                        Create Account
                      </a>
                    </div>
                    {/** VIEW ALL BUTTON */}
                    <div>
                      <div style={{ marginBottom: "20px" }}>
                        <Button
                          variant="contained"
                          icon="pi pi-external-link"
                          style={{ backgroundColor: "#ff007f", border: "none" }}
                          onClick={fetchAllMenusFunction}
                          label={"View All Products "}
                        />
                      </div>
                    </div>
                    <div
                      className="iconContainer"
                      style={{ cursor: "pointer", marginTop: "-10px" }}
                    >
                      <Facebook
                        className="menu"
                        onClick={() => {
                          window.open(
                            "https://web.facebook.com/profile.php?id=100088279560422",
                            "_blank"
                          );
                        }}
                      />{" "}
                      <Instagram
                        className="menu"
                        onClick={() => {
                          window.open(
                            "https://www.instagram.com/countclubwholesale_gh/",
                            "_blank"
                          );
                        }}
                      />{" "}
                      <WhatsApp
                        className="menu"
                        onClick={() => {
                          window.open("https://wa.me/+233550270851", "_blank");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </HeadlessUIDialog.Panel>
            </HeadlessUIDialog>
          </header>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
