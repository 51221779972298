import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Badge } from "primereact/badge";

function Customers(props) {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchCustomers = () => {
    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/vendorCustomers/${localStorage.getItem("foodDeliveryCompanyId")}`)
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchCustomers();
  }, []);
  const retrieveCustomerOrders = (id, filename, name) => {
    console.log("the customer id is ", id);
    localStorage.setItem("customerId", id);
    localStorage.setItem("customerFilename", filename);
    localStorage.setItem("customerName", name);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      props.fetchCustomerOrders();
    }, 2000);
  };
  return (
    <div>
      {" "}
      {loading ? (
        <div className="card" style={{ position: "absolute", zIndex: "99" }}>
          {" "}
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      ) : (
        ""
      )}
      <Grid item xs="auto" sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ fontSize: "15px", margin: "20px" }}>
          List of Customers
          <span style={{ fontWeight: "bold" }}>
            {" "}
            <Badge
              style={{ marginLeft: "3px" }}
              value={customers.length}
              severity="success"
            ></Badge>
          </span>
        </Typography>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: "whitesmoke" }}>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow>
                <Avatar
                  alt="Customer Image"
                  src={`../../../uploadImages/${customer.filename}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                />
                <TableCell>
                  {customer.firstname} {customer.lastname}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() =>
                      retrieveCustomerOrders(
                        customer.id,
                        customer.filename,
                        customer.firstname + " " + customer.lastname
                      )
                    }
                    style={{ marginRight: "5px" }}
                  >
                    View orders
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Customers;
