import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  CssBaseline,
  Paper,
  Avatar,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Messages } from "primereact/messages";
import { useRef } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import AOS from "aos";

function LoginForm() {
  const msgs = useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await handleSubmit();
    } catch (error) {
      // Handle errors here
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.get("https://reminiscent-swim-production.up.railway.app/api/company");

      const vendors = response.data;

      // Check if there's a company with the entered email and password
      const matchingCompany = vendors.find(
        (company) => company.email === email && company.password === password
      );

      if (matchingCompany) {
        console.log(
          "the application name is ",
          matchingCompany.applicationName
        );
        if (matchingCompany.status === "Active") {
          // Navigate to the dashboard if a matching company is found
          localStorage.setItem("companyId", matchingCompany.id);
          localStorage.setItem("companyName", matchingCompany.companyName);
          localStorage.setItem("loginRole", "Admin");
          localStorage.setItem("companyEmail", matchingCompany.email);
          localStorage.setItem("companyAddress", matchingCompany.address);
          localStorage.setItem("companyTelephone", matchingCompany.telephone);
          localStorage.setItem("companyCurrency", matchingCompany.currency);
          localStorage.setItem(
            "companyLogoFilename",
            matchingCompany.logoFilename
          );
          localStorage.setItem(
            "companyFacebookHandle",
            matchingCompany.facebookHandle
          );
          localStorage.setItem(
            "companyWhatsappNumber",
            matchingCompany.whatsappNumber
          );
          localStorage.setItem("companyPassword", matchingCompany.password);

          localStorage.setItem("foodDeliveryCompanyId", matchingCompany.id);
          navigate("/dashboard");
        } else if (matchingCompany.status === "Disabled") {
          msgs.current.show([
            {
              sticky: true,
              severity: "info",
              summary: "",
              detail:
                "The company account is disabled, contact Denyark Software Limited.",
              closable: true,
            },
          ]);
        }
      } else {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Invalid email or password.",
            closable: false,
          },
        ]);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Connection failed. check your internet connection",
          closable: false,
        },
      ]);
    }
  };

  return (
    <div className="myContainer">
      <Container component="main" maxWidth="xs" style={{ width: "100%" }} data-aos="fade-up">
        <div
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "25px",
          }}
        >
          <h1>Count Club Management System</h1>
        </div>
        <CssBaseline />
        <div>
          <div className="card flex justify-content-center">
            <Messages ref={msgs} />
          </div>
        </div>
        <div elevation={3} className="paper">
          <img className="App-logo"
            src={require("../../images/ccLogo.png")}
            style={{ width: "120px", height: "120px" }}
          />
          <form className="form" onSubmit={login}>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email Address"
              style={{ width: "100%" }}
            />
            <InputText
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
              style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
              toggleMask
            />

            {loading ? (
              <div
                className="card"
                style={{ position: "absolute", zIndex: "99" }}
              >
                {" "}
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="success"
                className="submit"
              >
                Sign In
              </Button>
            )}
          </form>
          <div style={{cursor:"pointer"}}onClick={() => {
              window.open(
                "https://www.denyark.com",
                "_blank"
              );
            }}>
          <h2 style={{fontSize:"12px",color:"gray",marginTop:"10px"}}>Developed by</h2>
          <p style={{color:"gray",textDecoration:"underline"}}>Denyark Software Limited</p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default LoginForm;
