import React from "react";
import Container from "./Container";
import Customers from "./customers/Customers";
import CustomerOrderDetails from "./customers/CustomerOrderDetails";

function Customers1(props) {
  return (
    <div>
      <CustomerOrderDetails />
    </div>
  );
}

export default Customers1;
