import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import AOS from "aos";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";

function CustomerOrders() {
  const msgs = useRef(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [customerId, setCustomerId] = useState(
    localStorage.getItem("loginCustomerId")
  );
  const [filename, setFilename] = useState("1.jpg");
  const [productName, setProductName] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [dateOrdered, setDateOrdered] = useState("");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchOrders = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orderByCustomerId/${localStorage.getItem(
          "loginCustomerId"
        )}`
      )
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const flexContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "40px",
    borderRadius: "6px",
    justifyContent: "center",
  };

  const gridItemStyle = {
    flex: "1 1 100%",
    maxWidth: "300px",
    margin: "5px",
    textAlign: "center",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "6px",
    cursor: "pointer",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  };
  const viewOrder = (
    orderId,
    filename,
    productName,
    quantity,
    totalPrice,
    unitPrice,
    dateOrdered,
    status
  ) => {
    setOrderId(orderId);
    setFilename(filename);
    setProductName(productName);
    setQuantity(quantity);
    setTotalPrice(totalPrice);
    setUnitPrice(unitPrice);
    setDateOrdered(dateOrdered);
    setStatus(status);
    setVisible(true);
  };
  const getStatusColorClass = (status) => {
    switch (status) {
      case "New":
        return "text-blue-500";
      case "Shipped":
        return "text-yellow-500";
      case "Processing":
        return "text-green-500";
      case "Delivered":
        return "text-green-500";
      default:
        return "text-gray-600";
    }
  };

  const login = () => {
    navigate("/userAccount");
  };

  const cancelOrder = (id) => {
    setLoading(true);
    axios
      .delete(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/order/${id}`
      )
      .then((response) => {
        if (response.status >= 204) {
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Order cancelled.",
              closable: false,
            },
          ]);
          setLoading(false);
          setVisible(false);
          fetchOrders();
        } else {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Failed to cancel  order. check internet connection !!",
              closable: false,
            },
          ]);
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };

  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <Header />
      <div>
        <div className="customerOrderContainer">
          {customerId ? (
            <div>
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "30px",
                }}
                data-aos="fade-up"
                className="myTitle"
              >
                My Orders - <span>{orders.length}</span>
              </h1>
              <p data-aos="fade-up">
                Explore your recent orders and enjoy hassle-free delivery!
              </p>
              <div style={flexContainerStyle}>
                {orders.map((order) => (
                  <div
                    style={gridItemStyle}
                    data-aos="fade-up"
                    onClick={() =>
                      viewOrder(
                        order.id,
                        order.foodDeliveryMenu.filename,
                        order.foodDeliveryMenu.foodName,
                        order.quantity,
                        order.total,
                        order.price,
                        order.newOrderDate,
                        order.status
                      )
                    }
                  >
                    <div>
                      <div class="foodDeliveryWebsiteIcon">
                        <i className="pi pi-shopping-cart"></i>
                      </div>
                      <div
                        class="text"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <p>{order.foodDeliveryMenu.foodName}</p>
                      </div>
                      <div>
                        <p className="text-gray-600">
                          Date Ordered:{" "}
                          {new Date(order.newOrderDate).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </p>
                      </div>
                      <div className={getStatusColorClass(order.status)}>
                        {order.status}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <p>You are not logged in. Please log in to view your orders.</p>
              <Button
                variant="contained"
                size="small"
                label="Login"
                type="submit"
                raised
                style={{
                  width: "100%",
                  backgroundColor: "#ff007f",
                  border: "none",
                  marginTop: "10px",
                }}
                onClick={login}
              />
            </div>
          )}
        </div>
      </div>
      {/**DIALOG */}
      <Dialog
        header="Order Details"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <div className="bg-white p-4 shadow-md rounded-md">
          <p className="text-gray-600">
            <span className="font-semibold">{productName}</span>
          </p>
          <p className="text-gray-600 mb-3">
            Date Ordered:{" "}
            {new Date(dateOrdered).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </p>
          <p className="text-gray-600">
            Unit Price: ₵{" "}
            {unitPrice.toLocaleString("en-GH", {
              minimumFractionDigits: 2,
            })}
          </p>
          <p className="text-gray-600">Quantity: {quantity}</p>
          <p className="text-gray-600">
            Total Price: ₵{" "}
            {totalPrice.toLocaleString("en-GH", {
              minimumFractionDigits: 2,
            })}
          </p>
          <p className="text-gray-600">
            Status:
            <span className={getStatusColorClass(status)}>
              <span className="font-semibold"> {status}</span>
            </span>
          </p>
          {loading ? (
            <div
              className="card"
              style={{ position: "absolute", zIndex: "99" }}
            >
              {" "}
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          ) : (
            <Button
              variant="contained"
              size="small"
              label="Cancel this order"
              type="submit"
              icon="pi pi-trash"
              raised
              style={{
                backgroundColor: "#ff007f",
                border: "none",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => cancelOrder(orderId)}
            />
          )}
          <img
            src={require(`../../uploadImages/${filename}`)}
            className="mb-4 w-full "
            style={{ maxHeight: "fit-content" }}
          />
        </div>
      </Dialog>
      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>
    </div>
  );
}

export default CustomerOrders;
