import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Paper from "@mui/material/Paper";
import { ProgressSpinner } from "primereact/progressspinner";

function MyReview(props) {
  const [customerReviews, setCustomerReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCustomerReviews = () => {
    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customerReviews/${localStorage.getItem("foodDeliveryCompanyId")}`)
      .then((response) => {
        setCustomerReviews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchCustomerReviews();
  }, []);
  const findOrderByOrderNumber = (orderId) => {
    console.log("the order id  is ", orderId);
    localStorage.setItem("findOrderByOrderId", orderId);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      props.findOrderByOrderId();
    }, 2000);

  };
  return (
    <div>
      {" "}
      {loading ? (
        <div className="card" style={{position:"absolute",zIndex:"99",marginTop:"-50px"}}>
          {" "}
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      ) : (
        ""
      )}
      <div>
        {customerReviews.map((order) => (
          <Paper
            elevation={3}
            sx={{
              p: 1,
              marginBottom: 2,
              backgroundColor: "whitesmoke",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs>
                <Grid item>
                  <Avatar
                    alt="img"
                    src={`../../../uploadImages/${order.foodDeliveryCustomer.filename}`}
                  />
                </Grid>
                <Rating name="read-only" value={order.review} readOnly />
                <Typography variant="subtitle1">
                  {order.foodDeliveryCustomer.firstname} {order.foodDeliveryCustomer.lastname}
                </Typography>
                <Typography variant="caption" style={{ width: "30px" }}>
                  {order.reviewComment}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "royalblue",
                      cursor: "pointer",
                    }}
                    onClick={() => findOrderByOrderNumber(order.id)}
                  >
                    view order details
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    </div>
  );
}

export default MyReview;
