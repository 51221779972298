import React from "react";

function Footer() {
    
  //MOVE TO THE DEVELOPER FACEBOOK
  const moveToDeveloperFacebook = () => {
    const url = "https://www.facebook.com/denyarksoftwareltd";
    window.open(url, "_blank");
  };

  //MOVE TO THE DEVELOPER WHATSAPP
  const moveToDeveloperWhatsapp = () => {
    const url = "https://wa.me/233545156156";
    window.open(url, "_blank");
  };
  
  //MOVE TO THE DEVELOPER WEBSITE
  const moveToDeveloperWebsite = () => {
    const url = "www.denyark.com";
    window.open(url, "_blank");
  };
  return (
    <div>
        <div className="footer-content">
          <div>
          <h1 style={{fontFamily:"monospace"}} className="copyrightText1"> &copy;Copyright {new Date().getFullYear()} All rights reserved | Count Club Wholesale
          </h1> 
          </div>
          <div
            className="copyright"
            style={{ cursor: "pointer"}}
            onClick={() => {
              window.open(
                "https://www.denyark.com",
                "_blank"
              );
            }}
          >Designed and Developed by <span style={{color:"gold"}}>Denyark Software Limited</span>
          </div>
        </div>
    </div>
  );
}

export default Footer;
