import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import axios from "axios";
import { Messages } from "primereact/messages";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";

const AddDispatchRider = (props) => {
  const msgs = useRef(null);
  const [riderName, setRiderName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [motorcycleNumber, setMotorcycleNumber] = useState("");
  const [motorcycleColor, setMotorcycleColor] = useState("");
  const [company, setCompany] = useState("1");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);

  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };

  const handleRegistration = async () => {
    // Handle registration logic here
    console.log({
      riderName,
      telephone,
      motorcycleNumber,
      motorcycleColor,
    });

    const filename = localStorage.getItem("imageName");
    try {
      const formData = {
        riderName,
        telephone,
        motorcycleNumber,
        motorcycleColor,
        filename,
        company: { id: company },
      };
      const response = await axios.post(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/riders`,
        formData
      );
      if (response.status >= 200) {
        setRiderName("");
        setTelephone("");
        setMotorcycleNumber("");
        setMotorcycleColor("");
        setSelectedFile("");
        props.fetchDispatchRiders();
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Success",
            detail: "Rider added successfully.",
            closable: false,
          },
        ]);
        localStorage.clear("imageName");
      } else {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Failed to register rider.",
            closable: false,
          },
        ]);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          closable: false,
        },
      ]);
    }
  };

  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <Box sx={{ marginTop: 4 }}>
        <div className="text-center flex">
          <div className="mx-auto">
            {localStorage.getItem("imageName") ? (
              <img
                alt="Customer"
                src={require(`../../../uploadImages/${localStorage.getItem(
                  "imageName"
                )}`)}
                className="w-16 h-16 rounded-full shadow-md mb-4"
              />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </div>

        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          Rider Registration
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Rider Name"
              variant="outlined"
              value={riderName}
              onChange={(e) => setRiderName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Telephone"
              variant="outlined"
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Registration Number"
              variant="outlined"
              value={motorcycleNumber}
              onChange={(e) => setMotorcycleNumber(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Vehicle Color"
              variant="outlined"
              type="color"
              value={motorcycleColor}
              onChange={(e) => setMotorcycleColor(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              style={{ width: "100%" }}
              endIcon={<AddAPhotoIcon />}
              onClick={() => setImageGalleryVisibility(true)}
            >
              Upload Image
            </Button>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="success"
          onClick={handleRegistration}
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          Register
        </Button>
      </Box>
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "50vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGallery />
      </Dialog>
    </div>
  );
};

export default AddDispatchRider;
