import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FileUpload } from "primereact/fileupload";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Messages } from "primereact/messages";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";

const EditDispatchRider = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };
  const msgs = useRef(null);
  const navigate = useNavigate();
  const [id, setId] = useState(localStorage.getItem("riderId"));
  const [riderName, setRiderName] = useState(localStorage.getItem("riderName"));
  const [telephone, setTelephone] = useState(
    localStorage.getItem("riderTelephone")
  );
  const [riderNumber, setRiderNumber] = useState(
    localStorage.getItem("riderNumber")
  );
  const [riderColor, setRiderColor] = useState(
    localStorage.getItem("riderColor")
  );
  const [riderFilename, setFilename] = useState(
    localStorage.getItem("imageName")
  );
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);

  const handleEditRider = () => {
    // Handle registration logic here
    console.log({
      riderName,
      telephone,
      riderNumber,
      riderColor,
    });

    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/rider/${id}`;

    const dataToUpdate = {
      riderName: riderName,
      telephone: telephone,
      motorcycleNumber: riderNumber,
      motorcycleColor: riderColor,
      filename: localStorage.getItem("imageName"),
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        props.fetchDispatchRiders();
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Rider updated successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error updating rider",
            error,
            closable: false,
          },
        ]);
      });
  };

  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <div style={{ textAlign: "center" }}>
          <img
            alt="Customer"
            src={require(`../../../uploadImages/${localStorage.getItem(
              "imageName"
            )}`)}
            style={{
              width: "100%",
              height: "200px",
              borderRadius: 8,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              marginBottom: 20,
            }}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Rider Name"
              variant="outlined"
              value={riderName}
              onChange={(e) => setRiderName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Telephone"
              variant="outlined"
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Rider Number"
              variant="outlined"
              value={riderNumber}
              onChange={(e) => setRiderNumber(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Ride Color"
              variant="outlined"
              type="color"
              value={riderColor}
              onChange={(e) => setRiderColor(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              style={{ width: "100%" }}
              endIcon={<AddAPhotoIcon />}
              onClick={() => setImageGalleryVisibility(true)}
            >
              Upload Image
            </Button>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="success"
          onClick={handleEditRider}
          sx={{ marginTop: 2, marginBottom: 2 }}
        >
          Save Changes
        </Button>
      </Box>
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "50vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGallery />
      </Dialog>
    </div>
  );
};

export default EditDispatchRider;
