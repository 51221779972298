import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Messages } from "primereact/messages";
import { Sync } from "@mui/icons-material";

function EditWorkingDays(props) {
  const msgs = useRef(null);
  const [id, setId] = useState(localStorage.getItem("brandId"));
  const [brandName, setBrandName] = useState(
    localStorage.getItem("brandName")
  );
  const [company, setCompany] = useState("1");

  const handleEditBrand = () => {
    console.log("the working day id is ", id);

    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/brand/${id}`;

    const dataToUpdate = {
      brandName: brandName,
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Brand name updated successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error updating brand name",
            error,
            closable: false,
          },
        ]);
      });
  };
  const editWorkingDays = async () => {
    try {
      // First, get the list of working days
      const brandResponse = await axios.get(
        `https://reminiscent-swim-production.up.railway.app/api/brand/${company}`
      );

      if (brandResponse.status === 200) {
        const existingBrandName = brandResponse.data;

        // Check if the working day already exists in the list
        const exists = existingBrandName.some(
          (day) => day.brandName === brandName
        );

        if (exists) {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Brand name already exists.",
              closable: false,
            },
          ]);
        } else {
          handleEditBrand();
        }
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          closable: false,
        },
      ]);
    }
  };
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <form className="form">
          <TextField
              label="Brand Name"
              variant="outlined"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              fullWidth
            />
        <Button
          variant="contained"
          color="success"
          className="submit"
          style={{marginTop:"10px"}}
          onClick={editWorkingDays}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}

export default EditWorkingDays;
