import React, { useEffect, useRef, useState } from "react";
import { Messages } from "primereact/messages";

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImagePath, setSelectedImagePath] = useState(null);
  const msgs = useRef(null);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [visibleImages, setVisibleImages] = useState(10); // Number of initially visible images
  const [loadMoreClicked, setLoadMoreClicked] = useState(1); // Load more button click count

  useEffect(() => {
    // Fetch the images from the folder
    const importAll = (r) => r.keys().map(r);
    const imageContext = require.context(
      "./uploadImages",
      false,
      /\.(png|jpe?g|svg)$/
    );
    const imagePaths = importAll(imageContext);

    setImages(imagePaths);
  }, []);

  const handleImageClick = (imagePath) => {
    setSelectedImagePath(imagePath);
    localStorage.setItem(
      "imageName",
      imagePath.split("/").pop().split(".")[0] + ".jpg"
    );
    msgs.current.show([
      {
        sticky: false,
        severity: "success",
        summary: "Success",
        detail: `Image selected, you can close the gallery `,
        closable: false,
      },
    ]);
  };

  const handleImageHover = (index) => {
    setHoveredImage(index);
  };

  const handleImageLeave = () => {
    setHoveredImage(null);
  };

  const handleLoadMoreClick = () => {
    setLoadMoreClicked((prevCount) => prevCount + 1);
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 10);
  };

  return (
    <div className="container mx-auto">
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-4">Products Image Gallery</h2>
      <div className="flex flex-wrap justify-center">
        {images.slice(0, visibleImages).map((image, index) => (
          <div
            key={index}
            className="m-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/8 max-h-32 cursor-pointer relative"
            onMouseEnter={() => handleImageHover(index)}
            onMouseLeave={handleImageLeave}
          >
            <img
              src={image}
              alt={`Image ${index}`}
              className={`w-full h-full ${
                hoveredImage === index ? "image-hovered" : ""
              }`}
            />
            {hoveredImage === index && (
              <button
                onClick={() => handleImageClick(image)}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white px-4 py-2 rounded-md opacity-100 transition-opacity duration-300 hover:opacity-100"
                style={{ backgroundColor: "#ff007f" }}
              >
                Select
              </button>
            )}
          </div>
        ))}
      </div>
      {visibleImages < images.length && (
        <div className="text-center mt-4">
          <button
            onClick={handleLoadMoreClick}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Load More Images 
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
