import DispatchRiders from "./diapatchRiders/DispatchRiders";

function Riders() {
  return (
    <div>
      <DispatchRiders />
    </div>
  );
}

export default Riders;
