import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dialog as HeadlessUIDialog } from "@headlessui/react";

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Alert,
  AppBar,
  Box,
  Grid,
  IconButton,
  Rating,
  Tooltip,
} from "@mui/material";
import { Button } from "primereact/button";
import { Toolbar } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Messages } from "primereact/messages";
import { usePaystackPayment } from "react-paystack";
import Badge from "@mui/material/Badge";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import Footer from "../../company/Footer";
import { useNavigate } from "react-router-dom";
import Header from "../../company/Header";
import BottomNavBAr from "../../company/BottomNavBar";
import FoodDeliveryAddCustomer from "../../company/FoodDeliveryAddCustomer";

function OrderNow() {
  const [deliveryTo, setDeliveryTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState("top");
  const [cartFoodTotalPrice, setCartFoodTotalPrice] = useState(0);
  const [quantity, setCount] = useState(1);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [cartFoodDescription, setCartDescription] = useState(
    localStorage.getItem("cartFoodDescription")
  );

  const [cartFoodPrice, setCartFoodPrice] = useState(
    localStorage.getItem("cartFoodPrice")
  );
  const [cartFoodId, setCartFoodId] = useState(
    localStorage.getItem("cartFoodId")
  );
  const [priceForCalculations, setPriceForCalculations] = useState(
    localStorage.getItem("priceForCalculations")
  );
  const [customerId, setCustomerId] = useState(
    localStorage.getItem("loginCustomerId")
  );
  const [customerFirstname, setCustomerFirstname] = useState(
    localStorage.getItem("loginCustomerFirstname")
  );
  const [customerLastname, setCustomerLastname] = useState(
    localStorage.getItem("loginCustomerLastname")
  );
  const [cartFoodImage, setCartFoodImage] = useState(
    localStorage.getItem("cartFoodImage")
  );
  const [cartFoodName, setCartFoodName] = useState(
    localStorage.getItem("cartFoodName")
  );
  const [brandName, setBrandName] = useState(localStorage.getItem("brandName"));
  const [visible, setVisible] = useState(false);
  const [customerFilename, setCustomerFilename] = useState("");
  const [customerLoginVisible, setCustomerLoginVisible] = useState(false);
  const [customerSignUpVisible, setCustomerSignUpVisible] = useState(false);
  const [customerConfirmVisible, setCustomerConfirmVisible] = useState(false);
  const msgs = useRef(null);
  const [menus, setMenus] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [greeting, setGreeting] = useState("");
  const [showGreeting, setShowGreeting] = useState(false);

  const fetchMenus = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/fetchProductByBrand/1/${brandName}`
      )
      .then((response) => {
        setMenus(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  /*useEffect(() => {
    fetchMenus();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  */ useEffect(() => {
    const fetchData = async () => {
      await fetchMenus();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    fetchData();
  }, []); // Empty dependency array to ensure it runs only once on mount

  const buttonStyles = {
    backgroundColor: "#ff007f",
    color: "white",
    size: 5,
    width: "20px",
    height: "20px",
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleDecrement = () => {
    if (quantity > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };
  const logout = () => {
    setCustomerId("");
    localStorage.removeItem("loginCustomerId");
    localStorage.removeItem("loginCustomerFirstname");
    localStorage.removeItem("loginCustomerLastname");
    msgs.current.show([
      {
        sticky: false,
        severity: "success",
        summary: "Success",
        detail: "Logout successfully.",
        closable: false,
      },
    ]);
    setCustomerConfirmVisible(false);
  };

  useEffect(() => {
    setCartFoodTotalPrice(priceForCalculations * quantity);
  }, [quantity, priceForCalculations]);

  const orderNow = (e) => {
    e.preventDefault();
    /*alert(customerId);*/
    if (customerId == null) {
      setCustomerLoginVisible(true);
    } else if (customerId == "") {
      setCustomerLoginVisible(true);
    } else {
      handleGreeting();
      setCustomerConfirmVisible(true);

      //initializePayment(onSuccess, onClose);
    }
  };

  const yesPlaceOrder = () => {
    setCustomerConfirmVisible(false);
    placeOrder();
  };
  const noDontOrder = () => {
    setCustomerConfirmVisible(false);
  };

  {
    /** EXECUTE THIS FUNCTION WHEN THE LOGIN BUTTON IS CLICKED */
  }
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      login();
    }, 2000);
  };

  {
    /**FUNCTION TO LOGIN THE CUSSTOMER */
  }
  const login = async () => {
    console.log(formData);
    console.log("the telephone is ", formData.telephone);
    console.log("the password is ", formData.password);
    try {
      // Perform the API call to retrieve user data based on telephone
      const response = await fetch(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customerByTelephone/1/${formData.telephone}`
      );

      if (!response.ok) {
        throw new Error("Invalid response from the server");
      }
      const customerData = await response.json();

      if (customerData && customerData.password === formData.password) {
        setCustomerId(customerData.id);
        setCustomerFirstname(customerData.firstname);
        setCustomerLastname(customerData.lastname);
        setCustomerFilename(customerData.filename);
        localStorage.setItem("loginCustomerId", customerData.id);
        localStorage.setItem("loginCustomerFirstname", customerData.firstname);
        localStorage.setItem("loginCustomerLastname", customerData.lastname);
        formData.telephone = "";
        formData.password = "";
        setCustomerLoginVisible(false);
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Success",
            detail: "login successfully.",
            closable: false,
          },
        ]);
      } else {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Incorrect password.",
            closable: false,
          },
        ]);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Incorrect telephone or password. Please try again later",
          closable: false,
        },
      ]);
    }
  };

  {
    /** NAVIGATE TO THE NEXT TAB WHEN THE SIGN UP LINK IS CLIKED */
  }
  const addCustomer = () => {
    setCustomerSignUpVisible(true);
  };

  {
    /**PAYMENT GATEWAY */
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: "nyarkodesjr@gmail.com",
    amount: cartFoodTotalPrice * 100,
    currency: "GHS",
    publicKey: "pk_test_1935bae8273f7706297cad266a58766948183d33",
    //publicKey: "pk_live_e3e0304574b1cbd7c7ec0acae6deb6018f508b05",
  };
  const initializePayment = usePaystackPayment(config);

  // SUCCESS FUNCTION FROM ;
  const onSuccess = (reference) => {
    console.log(reference);
    console.log("order placed successfully");
    setVisible(false);
    placeOrder();
  };

  const onClose = () => {
    console.log("closed");
  };

  {
    /**LOGIN FORM DATA */
  }
  const [formData, setFormData] = useState({
    telephone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // CLOSE FUNCTION FROM PAYSTACK
  const placeOrder = async () => {
    const newOrderDate = new Date();

    const formattedNewOrderDate = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(newOrderDate);

    // Remove colons from the formattedNewOrderDate
    const timeWithoutColons = formattedNewOrderDate.replace(/:/g, "");

    // Prepare the order data to send to the API
    const orderData = {
      /* company: { id: localStorage.getItem(
        "foodDeliveryCompanyId"
      ) },*/
      foodDeliveryCustomer: { id: customerId },
      foodDeliveryMenu: { id: cartFoodId },
      orderNumber: customerId + timeWithoutColons,
      quantity: quantity,
      //comment: message.toString(),
      deliveryTo: deliveryTo,
      price: cartFoodPrice,
      total: cartFoodTotalPrice,
      source: "Web",
    };

    // Send the order data to the API endpoint
    try {
      const response = await fetch(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        msgs.current.show([
          {
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: "Failed to place the order. Please try again later.",
            closable: true,
          },
        ]);
      }

      msgs.current.show([
        {
          sticky: true,
          severity: "success",
          summary: "Success",
          detail: "Order placed successfully!.",
          closable: true,
        },
      ]);
    } catch (error) {
      msgs.current.show([
        {
          sticky: true,
          severity: "error",
          summary: "Error",
          detail: error,
          closable: true,
        },
      ]);
    }
  };

  const addToCart = (
    foodId,
    foodName,
    foodPrice,
    foodDescription,
    foodImage,
    brandName
  ) => {
    setCartFoodId(foodId);
    setCartFoodName(foodName);
    setCartFoodPrice(foodPrice);
    setCartDescription(foodDescription);
    setCartFoodImage(foodImage);
    setPriceForCalculations(foodPrice);
    setBrandName(brandName);
    setCount(1);
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };
  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return "Good morning!";
    } else if (currentHour >= 12 && currentHour < 18) {
      return "Good afternoon!";
    } else {
      return "Good evening!";
    }
  };

  const handleGreeting = () => {
    setGreeting(getGreetingMessage());
    setShowGreeting(true);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          backgroundColor: "#ff007f",
          padding: "10px",
          color: "white",
          position: "fixed",
          width: "100%",
          zIndex: "999",
          height: "60px",
        }}
      ></div>
      <br />
      <br />
      <br />
      <br />
      <div className="orderNowContainer">
        <div className="orderNowCol1">
          <img
            src={require(`../../../uploadImages/${cartFoodImage}`)}
            alt="Image"
            className="zoomable-image orderNowImage"
          />
        </div>
        <div className="orderNowCol2">
          <div>
            <p
              style={{
                fonntWeight: "bold",
                fontSize: "20px",
              }}
            >
              {cartFoodName}
            </p>
          </div>

          <div>
            <span
              style={{
                color: "#ff007f",
                fontWeight: "bold",
              }}
            >
              {" "}
              GHS{" "}
              {cartFoodPrice.toLocaleString("en-GH", {
                minimumFractionDigits: 2,
              })}
            </span>
          </div>

          <div>
            <p
              style={{
                fonntWeight: "bold",
                fontSize: "25px",
              }}
            >
              Product Description
            </p>
            <p style={{ fontSize: "15px", marginBottom: "20px" }}>
              {cartFoodDescription}
            </p>
          </div>

          <div
            className="foodDeliveryCartBox"
            style={{ backgroundColor: "whitesmoke", padding: "10px" }}
          >
            <div>
              <p>Quantity</p>
            </div>
            <div>
              <div>
                <IconButton style={buttonStyles} onClick={handleDecrement}>
                  <RemoveIcon sx={{ fontSize: "12px" }} />
                </IconButton>
                <span
                  className="boldText"
                  style={{ marginLeft: "4px", marginRight: "4px" }}
                >
                  {quantity}
                </span>
                <IconButton style={buttonStyles} onClick={handleIncrement}>
                  <AddIcon sx={{ fontSize: "12px" }} />
                </IconButton>
              </div>
            </div>
          </div>
          <div
            className="foodDeliveryCartBox"
            style={{
              backgroundColor: "whitesmoke",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div>
              <p>Total Price</p>
            </div>
            <div>
              <p className="boldText">
                GHS{" "}
                {cartFoodTotalPrice.toLocaleString("en-GH", {
                  minimumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>

          <form
            className="form"
            onSubmit={orderNow}
            style={{ backgroundColor: "whitesmoke", padding: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputText
                  value={deliveryTo}
                  onChange={(e) => setDeliveryTo(e.target.value)}
                  type="text"
                  required
                  placeholder="Delivery To"
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    height: "30px",
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              label="Order now and pay on delivery"
              iconPos="right"
              size="small"
              severity="warning"
              style={{ width: "100%" }}
            />
          </form>
        </div>
      </div>

      {/** MENU */}
      <div className="foodDeliveryWebsiteContainer" id="menu">
        <div className="foodDeliveryWebsiteColumn1">
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <h2
              style={{
                fontWeight: "bold",
                fontSize: "30px",

                color: "#373838",
              }}
            >
              <span style={{ color: "#ff007f" }}>{menus.length}</span>{" "}
              <span className="myTitle"> Similar Products</span>
            </h2>
          </div>

          {isLoading ? (
            // Display skeleton or loading indicator while data is fetching
            <div
              className="skeleton-container"
              style={{ color: "red", textAlign: "center" }}
            >
              {/* Your skeleton loading component/style goes here */}
              <span>Loading... </span>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {/* Skeleton Loader Item */}
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
                <div className="skeleton-item"></div>
              </div>
            </div>
          ) : (
            <div className="grid-container">
              {menus.map((menu) => (
                <div
                  className="grid-item"
                  onClick={() =>
                    addToCart(
                      menu.id,
                      menu.foodName,
                      menu.price,
                      menu.foodDescription,
                      menu.filename,
                      menu.brand.brandName
                    )
                  }
                >
                  <img
                    src={require(`../../../uploadImages/${menu.filename}`)}
                    alt=""
                    style={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "8px",
                    }}
                  />
                  <div style={{ textAlign: "left", padding: "10px" }}>
                    <p style={{ fontSize: "12px" }}>
                      {menu.foodName} -{" "}
                      <span style={{ fontWeight: "bold", color: "#ff007f" }}>
                        {" "}
                        GHS {menu.price}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/**FOOTER */}
      <footer className="footer">
        <Footer />
      </footer>

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>

      {/**MOVE BACK
      <div
        style={{ position: "fixed", right: "30px", bottom: "60px" }}
        className="mobileVisibility"
      >
        <Tooltip title="Home">
          <Button
            icon="pi pi-home"
            rounded
            raised
            style={{ backgroundColor: "gold", border: "none" }}
            aria-label="Notification"
            onClick={moveBack}
            title="Home"
          />
        </Tooltip>{" "}
      </div> */}

      {/**CUSTOMER LOGIN MODAL */}
      <Dialog
        header=""
        visible={customerLoginVisible}
        position={position}
        onHide={() => setCustomerLoginVisible(false)}
        style={{ width: "50vw", width: "400px", marginTop: "60px" }}
      >
        <div className="foodDeliveryWebsiteCartContainer">
          <div>
            <div
              style={{
                backgroundColor: "#ff007f",
                width: "100%",
                padding: "15px",
              }}
            >
              <p style={{ color: "white" }}>Please fill the form</p>
            </div>
            <div
              className="customer-registration-form"
              style={{ padding: "20px" }}
            >
              <form onSubmit={handleLogin}>
                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-phone"></i>
                  </span>
                  <InputText
                    type="tel"
                    placeholder="Telephone"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <InputText
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                {loading ? (
                  <div
                    className="card"
                    style={{ position: "absolute", zIndex: "99" }}
                  >
                    {" "}
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    icon="pi pi-check"
                    iconPos="right"
                    size="small"
                    severity="warning"
                    label="Login to continue"
                    type="submit"
                    style={{ width: "100%" }}
                  />
                )}

                <p
                  style={{ marginTop: "15px", textAlign: "center" }}
                  onClick={addCustomer}
                >
                  Don't have an account?{" "}
                  <span
                    style={{
                      color: "royalblue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Sign Up
                  </span>
                </p>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

      {/**CUSTOMER Signup MODAL */}
      <Dialog
        header=""
        visible={customerSignUpVisible}
        position={position}
        onHide={() => setCustomerSignUpVisible(false)}
        style={{ width: "50vw", width: "400px", marginTop: "60px" }}
      >
        <FoodDeliveryAddCustomer />
      </Dialog>

      {/** CONFIRMATION DIALOG */}
      <Dialog
        header=""
        visible={customerConfirmVisible}
        position={position}
        onHide={() => setCustomerConfirmVisible(false)}
        style={{ width: "50vw", width: "400px", marginTop: "60px" }}
      >
        <div style={{ padding: "10px", textAlign: "center" }}>
          <h1>
            {greeting}{" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {customerFirstname} {customerLastname}
            </span>
          </h1>
          <h1>Do you want to order this product and pay on delivery ?</h1>
          <di>
            <p
              style={{
                padding: "10px",
                backgroundColor: "red",
                color: "white",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={noDontOrder}
            >
              No
            </p>
            <p
              style={{
                padding: "10px",
                backgroundColor: "green",
                color: "white",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={yesPlaceOrder}
            >
              Yes order
            </p>
            <p
              style={{
                padding: "10px",
                color: "royalblue",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={logout}
            >
              log out
            </p>
          </di>
        </div>
      </Dialog>

      {/** MESSAGE MODAL */}
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
    </div>
  );
}

export default OrderNow;
