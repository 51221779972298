import LoginForm from "./components/company/LoginForm";
import Dashboard from "./components/foodDelivery/Dashboard";
import { Routes, Route } from "react-router-dom";
import Orders from "./components/foodDelivery/Orders";
import Menu from "./components/foodDelivery/Menu";
import Riders from "./components/foodDelivery/Riders";
import Reviews from "./components/foodDelivery/Reviews";
import Customers from "./components/foodDelivery/Customers";
import Setup from "./components/foodDelivery/Setup";
import Report from "./components/foodDelivery/Report";
import ViewOrder from "./components/foodDelivery/order/ViewOrder";
import AddMenu from "./components/foodDelivery/menu/AddMenu";
import EditMenu from "./components/foodDelivery/menu/EditMenu";
import RiderTrips from "./components/foodDelivery/diapatchRiders/RiderTrips";
import AddDispatchRider from "./components/foodDelivery/diapatchRiders/AddDispatchRider";
import EditDispatchRider from "./components/foodDelivery/diapatchRiders/EditDispatchRider";
import PrintSingleOrder from "./components/foodDelivery/report/PrintSingleOrder";
import PrintOrdersReport from "./components/foodDelivery/report/PrintOrdersReport";
import FoodDeliveryAddCustomer from "./components/company/FoodDeliveryAddCustomer";
import Account from "./components/account/Account";

/*Website for food delivery */
import FoodDeliveryWebsite from "./components/foodDelivery/website/FoodDeliveryWebsite";
import OrderNow from "./components/foodDelivery/website/OrderNow";
import AllProducts from "./components/foodDelivery/website/AllProducts";
import ImageGallery from "./ImageGallery";
import ImageGalleryCategory from "./ImageGalleryCategory";
import BottomNavBAr from "./components/company/BottomNavBar";
import Testimonies from "./components/company/Testimonies";
import Contact from "./components/company/Contact";
import Payment from "./components/company/Payment";
import Search from "./components/company/Search";
import CustomerOrders from "./components/company/Orders";
import UserAccount from "./components/company/UserAccount";
import ContactUS from "./components/company/Contact-us";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<FoodDeliveryWebsite />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/riders" element={<Riders />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/report" element={<Report />} />
        <Route path="/viewOrder" element={<ViewOrder />} />
        <Route path="/addMenu" element={<AddMenu />} />
        <Route path="/editMenu" element={<EditMenu />} />
        <Route path="/riderTrips" element={<RiderTrips />} />
        <Route path="/account" element={<Account />} />
        <Route path="/addDispatchRider" element={<AddDispatchRider />} />
        <Route path="/editDispatchRider" element={<EditDispatchRider />} />
        <Route path="/printSingleOrder" element={<PrintSingleOrder />} />
        <Route path="/printOrdersReport" element={<PrintOrdersReport />} />
        <Route path="/addCustomer" element={<FoodDeliveryAddCustomer />} />
        <Route path="/orderNow" element={<OrderNow />} />
        <Route path="/allProducts" element={<AllProducts />} />
        <Route path="/imageGallery" element={<ImageGallery />} />
        <Route path="/imageGalleryCategory" element={<ImageGalleryCategory />} />
        <Route path="/bottomNavbar" element={<BottomNavBAr />} />
        <Route path="/testimonies" element={<Testimonies />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/search" element={<Search />} />
        <Route path="/customerOrders" element={<CustomerOrders />} />
        <Route path="/userAccount" element={<UserAccount />} />
        <Route path="/contact-us" element={<ContactUS />} />
      </Routes>
    </div>
  );
}

export default App;
