import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

function SearchContainer() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const searchByFoodName = () => {
    if (!searchText == "") {
      localStorage.setItem("source", "searchFromNav");
      localStorage.setItem("searchText", searchText);
      navigate("/allProducts");
    }
  };

  const fetchAllMenusFunction = () => {
    localStorage.setItem("source", "products");
    navigate("/allProducts");
  };

  return (
    <div>
      <div className="searchBgImage">
        <div className="searchContainer">
          <div>
            <div
              style={{
                justifyContent: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontFamily: "monospace",
                marginTop: "40px",
              }}
              className="myTitle"
            >
              <p>Filter Products</p>
            </div>

            <div>
              <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                <InputText
                  placeholder="Search for product"
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "whitesmoke",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  required
                />
                <Tooltip title="Search">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      backgroundColor: "#ff007f",
                      cursor: "pointer",
                      color: "white",
                    }}
                  >
                    <i className="pi pi-search" onClick={searchByFoodName}></i>
                  </span>
                </Tooltip>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  raised
                  style={{
                    backgroundColor: "gold",
                    border: "none",
                    color: "#fff",
                    width: "100%",
                  }}
                  label={"View All products"}
                  onClick={fetchAllMenusFunction}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SearchContainer;
