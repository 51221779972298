import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";

function PrintOrdersReport() {
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState(localStorage.getItem("startDate"));
  const [endDate, setEndDate] = useState(localStorage.getItem("endDate"));
  const [totalAmount, setTotalAmount] = useState(0);
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );
  const [status, setStatus] = useState(localStorage.getItem("status"));
  const foodDeliveryCompanyId = localStorage.getItem("foodDeliveryCompanyId");

  axios
    .get(
      `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders/report/${foodDeliveryCompanyId}/${status}?startDate=${startDate}&endDate=${endDate}`
    )
    .then((response) => {
      setOrders(response.data);

      const totalAmount = response.data.reduce((accumulator, order) => {
        return accumulator + order.total;
      }, 0);
      setTotalAmount(totalAmount);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  const printReceipt = () => {
    window.print();
  };
  return (
    <div style={{ width: "97%", margin: "0 auto" }}>
      <div
        style={{ textAlign: "right", marginBottom: "15px", marginTop: "10px" }}
      >
        <Button variant="contained" color="success" onClick={printReceipt}>
          Print
        </Button>
      </div>
      <div className="reportContainer">
        <div className="reportColumn1" style={{ width: "100%" }}>
          <div>
            <li>From : {startDate}</li>
            <li>To : {endDate}</li>
            <li>Total number of orders : {orders.length}</li>
            <li>
              Amount accumulated : {currency}
              {totalAmount.toLocaleString("en-GH", {
                minimumFractionDigits: 2,
              })}
            </li>
          </div>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bold", fontSize: "19px" }}>
              ORDERS REPORT
            </p>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "whitesmoke" }}>
                  <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Grand Total</TableCell>
                    <TableCell>Time Ordered</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        {" "}
                        {order.foodDeliveryCustomer.firstname} {order.foodDeliveryCustomer.lastname}
                      </TableCell>
                      <TableCell> {order.orderNumber}</TableCell>

                      <TableCell>{order.foodDeliveryMenu.foodName}</TableCell>
                      <TableCell>
                        {currency}
                        {order.price.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>{order.quantity}</TableCell>
                      <TableCell>
                        {" "}
                        {currency}
                        {order.total.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>
                        {new Date(order.newOrderDate).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintOrdersReport;
