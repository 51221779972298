import Container from "../Container";
import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Rating,
  Grid,
} from "@mui/material";
import { FileUpload } from "primereact/fileupload";
import axios from "axios";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";

function AddMenu(props) {
  const msgs = useRef(null);
  const [foodName, setFoodName] = useState("");
  const [foodDescription, setFoodDescription] = useState("");
  const [review, setReview] = useState(0);
  const [status, setStatus] = useState("");
  const [price, setPrice] = useState("");

  const [foodDeliveryCategory, setFoodDeliveryCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [foodCategories, setFoodCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("1");
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);

  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };

  const fetchCategories = () => {
    fetch(
      `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/categories/${localStorage.getItem(
        "foodDeliveryCompanyId"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming data is an array of food categories like ["category1", "category2"]
        setFoodCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching food categories:", error);
      });
  };
  const fetchBrands = () => {
    fetch(
      `https://reminiscent-swim-production.up.railway.app/api/brand/${localStorage.getItem(
        "foodDeliveryCompanyId"
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => {
        console.error("Error fetching brands:", error);
      });
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await register();
    } catch (error) {
      // Handle errors here
    }

    setLoading(false);
  };

  const register = async () => {
    // Perform registration logic here
    console.log(foodName);
    console.log(foodDescription);
    console.log(status);
    console.log(price);
    console.log(review);
    console.log(foodDeliveryCategory);

    const filename = localStorage.getItem("imageName");
    const imageName = localStorage.getItem("imageName");

    if (imageName === null) {
      // Display an alert if the value is null
      alert("Please select image for the product !!");
    } else {
      try {
        const formData = {
          foodName,
          foodDescription,
          status,
          price,
          review,
          foodDeliveryCategory,
          brand,
          filename,
          company: { id: company },
        };
        const response = await axios.post(
          `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menus`,
          formData
        );
        if (response.status >= 200) {
          setFoodName("");
          setFoodDescription("");
          setReview(0);
          setStatus("");
          setPrice("");
          setFoodDeliveryCategory("");
          setSelectedFile("");

          fetchMenus();
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Product added successfully.",
              closable: false,
            },
          ]);
        } else {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Failed to add product.",
              closable: false,
            },
          ]);
        }
      } catch (error) {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: error.response.data,
            closable: false,
          },
        ]);
      }
    }
  };
  const fetchMenus = () => {
    props.fetchMenus();
  };
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <div maxWidth="sm" style={{ textAlign: "center" }}>
        <div className="text-center flex">
          <div className="mx-auto">
            {localStorage.getItem("imageName") ? (
              <img
                alt="Customer"
                src={require(`../../../uploadImages/${localStorage.getItem(
                  "imageName"
                )}`)}
                className="w-16 h-16 rounded-full shadow-md mb-4"
              />
            ) : (
              <p>No image available</p>
            )}
          </div>
        </div>
        <Typography variant="h4" gutterBottom>
          Product Registration
        </Typography>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Item Name"
              variant="outlined"
              fullWidth
              value={foodName}
              onChange={(e) => setFoodName(e.target.value)}
              style={{ marginBottom: "1rem" }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Price */}
            <TextField
              label="Price"
              variant="outlined"
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{ marginBottom: "1rem" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Food Category Dropdown */}
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
            >
              <InputLabel>Brand</InputLabel>
              <Select
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                label="Brand"
              >
                {brands.map((brand) => (
                  <MenuItem key={brand} value={brand}>
                    {brand.brandName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Food Category Dropdown */}
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
            >
              <InputLabel>Category</InputLabel>
              <Select
                value={foodDeliveryCategory}
                onChange={(e) => setFoodDeliveryCategory(e.target.value)}
                label="Category"
              >
                {foodCategories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category.foodCategory}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Status Dropdown */}
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginBottom: "1rem" }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="in stock">IN STOCK</MenuItem>
                <MenuItem value="out of stock">OUT OF STOCK</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <div>
              <span style={{ fontSize: "15px" }}>
                <h3>Rating Star</h3>
                <Rating
                  name="read-only"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </span>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* Description */}
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={foodDescription}
              onChange={(e) => setFoodDescription(e.target.value)}
              style={{ marginBottom: "1rem" }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              style={{ width: "100%" }}
              endIcon={<AddAPhotoIcon />}
              onClick={() => setImageGalleryVisibility(true)}
            >
              Upload Image
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Register Button */}

            {loading ? (
              <div
                className="card"
                style={{ position: "absolute", zIndex: "99" }}
              >
                {" "}
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={handleRegister}
                fullWidth
              >
                Register
              </Button>
            )}
          </Grid>
        </Grid>
      </div>{" "}
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "70vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGallery />
      </Dialog>
    </div>
  );
}

export default AddMenu;
