import React from "react";
import Container from "./Container";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Categories from "./Category/Categories";
import AddCategory from "./Category/AddCategory";
import WorkingDays from "./workingDays/WorkingDays";
import AddWorkingDays from "./workingDays/AddWorkingDays";
function Setup() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <Container>
          <div className="setupContainer">
            <div className="setupColumn1">
              <div
                className="setupColumn1Col1"
                style={{
                  marginTop: "10px",
                  backgroundColor: "whitesmoke",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <Categories />
              </div><div
                className="setupColumn2Col1"
                style={{
                  marginTop: "10px",
                  backgroundColor: "whitesmoke",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <WorkingDays />
              </div>
            </div>
            <div
              className="setupColumn2"
              style={{
                marginTop: "10px",
                backgroundColor: "whitesmoke",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div className="setupColumn1Col1">
                <AddCategory />
              </div>
              <div className="setupColumn2Col1">
                <AddWorkingDays />
              </div>
             
            </div>
          </div>
        </Container>
    </div>
  );
}

export default Setup;
