import { createGlobalState } from "react-hooks-global-state";
// Define initial state
const vendorState = {
  verdorID: "",
};

global.server = "https://reminiscent-swim-production.up.railway.app/";
global.foodDeliveryMonthlysalesAPI = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/ordersMonthlySales/${localStorage.getItem(
  "foodDeliveryCompanyId"
)}`;
global.foodDeliveryChartAPI = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/monthlyOrdersChart/${localStorage.getItem(
  "foodDeliveryCompanyId"
)}`;
global.foodDeliveryOrdersAPI = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/vendor/orders/${localStorage.getItem(
  "foodDeliveryCompanyId"
)}`;
global.foodDeliveryOrdersAPIbyOrderNumber = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orderNumberLike/${localStorage.getItem(
  "foodDeliveryCompanyId"
)}/${localStorage.getItem("searchOrderNumber")}`;
global.fetchOrderDetails = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders/${localStorage.getItem(
  "orderId"
)}`;

// Create global state
const { setGlobalState, useGlobalState } = createGlobalState(vendorState);

// Export global state hooks
export { setGlobalState, useGlobalState };
