import React, { useEffect, useState } from "react";
import Container from "../Container";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";

function OrderReport() {
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );

  const fetchOrders = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders/report/${localStorage.getItem(
          "foodDeliveryCompanyId"
        )}/${status}?startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        setOrders(response.data);

        const totalAmount = response.data.reduce((accumulator, order) => {
          return accumulator + order.total;
        }, 0);
        setTotalAmount(totalAmount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handlePrint = () => {
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("status", status);
    const url = "/printOrdersReport";
    window.open(url, "_blank");
  };

  const retrieveStartDate = (date) => {
    const formattedStartDate = new Date(date.value).toISOString().split("T")[0];
    console.log(formattedStartDate);
    setStartDate(formattedStartDate);
  };
  const retrieveEndDate = (date) => {
    const formattedEndDate = new Date(date.value).toISOString().split("T")[0];
    console.log(formattedEndDate);
    setEndDate(formattedEndDate);
  };
  const generateReport = () => {
    console.log("start date ", startDate);
    console.log("end date ", endDate);
    console.log("status ", status);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      fetchOrders();
    }, 2000);
  };
  return (
    <div>
      <Container>
        <div className="reportContainer">
          <div className="reportColumn1">
            <div>
              <li>From : {startDate}</li>
              <li>To : {endDate}</li>
              <li>Total number of orders : {orders.length}</li>
              <li>
                Amount accumulated : {currency}
                {totalAmount.toLocaleString("en-GH", {
                  minimumFractionDigits: 2,
                })}
              </li>
            </div>
            <div style={{ textAlign: "center" }}>
              <p style={{ fontWeight: "bold", fontSize: "19px" }}>
                ORDERS REPORT
              </p>
            </div>
            <div>
              <TableContainer component={Paper} className="responsive-table">
                <Table>
                  <TableHead style={{ backgroundColor: "whitesmoke" }}>
                    <TableRow>
                      <TableCell>Customer</TableCell>
                      <TableCell>Order Number</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Grand Total</TableCell>
                      <TableCell>Time Ordered</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow key={order.id}>
                        <TableCell>
                          {" "}
                          {order.foodDeliveryCustomer.firstname} {order.foodDeliveryCustomer.lastname}
                        </TableCell>
                        <TableCell> {order.orderNumber}</TableCell>

                        <TableCell>{order.foodDeliveryMenu.foodName}</TableCell>
                        <TableCell>
                          {currency}
                          {order.price.toLocaleString("en-GH", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>{order.quantity}</TableCell>
                        <TableCell>
                          {" "}
                          {currency}
                          {order.total.toLocaleString("en-GH", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>
                          {new Date(order.newOrderDate).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="reportColumn2" style={{ padding: "10px" }}>
            <div>
              <p>Start Date :</p>
              <Calendar
                placeholder="Pick a date"
                dateFormat="mm/dd/yy"
                style={{ width: "100%"}}
                selected={startDate}
                onChange={retrieveStartDate}
              />
            </div>
            <div>
              <p>End Date :</p>
              <Calendar
                placeholder="Pick a date"
                dateFormat="mm/dd/yy"
                style={{ width: "100%" }}
                selected={endDate}
                onChange={retrieveEndDate}
              />
            </div>
            <div>
              <p>Status :</p>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Processed">Processing</MenuItem>
                  <MenuItem value="Shipped">Shipped</MenuItem>
                  <MenuItem value="Delivered">Delivered</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                variant="contained"
                color="success"
                onClick={generateReport}
                style={{ width: "100%", marginTop: "20px" }}
              >
                {loading ? (
                  <div
                    className="card"
                    style={{ position: "absolute", zIndex: "99" }}
                  >
                    {" "}
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  "Generate report"
                )}
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handlePrint}
                style={{ width: "100%", marginTop: "20px" }}
              >
                Print
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OrderReport;
