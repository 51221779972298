import React, { useEffect } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import { Grid } from "@mui/material";
import AOS from "aos";

function Payment() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <div className="weAcceptConatiner" id="payment">
        <div
        className="paymentTitleContainer"
        >
          <h1
            style={{ fontWeight: "bold", fontSize: "40px"}}
            data-aos="fade-up"
            className="myTitle "
          >
            We Accept
          </h1>
          <p data-aos="fade-up" style={{marginBottom:"-50px"}}>Order from us and pay on delivery</p>
        </div>
        <div className="weAcceptConatinerCol">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}  data-aos="zoom-in">
              <img
                src={require("../../images/MTN-Momo.jpeg")}
                alt="Image"
                className="weAcceptImage"
              />
            </div>
            <div style={{ width: "48%" }} data-aos="zoom-in">
              <img
                src={require("../../images/Vodafone-Cash.jpg")}
                alt="Image"
                className="weAcceptImage"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }} data-aos="zoom-in">
              <img
                src={require("../../images/airteltigo.png")}
                alt="Image"
                className="weAcceptImage"
              />
            </div>
            <div style={{ width: "48%" }} data-aos="zoom-in">
              <img
                src={require("../../images/visaPayment.png")}
                alt="Image"
                className="weAcceptImage"
              />
            </div>
          </div>
        </div>
      </div>

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>
    </div>
  );
}

export default Payment;
