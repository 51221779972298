import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, TextField, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import EditAccountImage from "./EditAccountImage";
import { ProgressSpinner } from "primereact/progressspinner";
import Container from "../foodDelivery/Container";
import logo from "../../images/ccLogo.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Account() {
  const msgs = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [id, setId] = useState(localStorage.getItem("companyId"));
  localStorage.setItem("vendorId", id);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [address, setAddress] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [currency, setCurrency] = useState("");
  const [password, setPassword] = useState("");
  const [logoFilename, setLogoFilename] = useState("");
  const [bannerFilename, setBannerFilename] = useState("");

  const fetchAccountDetails = () => {
    const url = `https://reminiscent-swim-production.up.railway.app/api/company/${id}`;
    console.log("from account id", id);
    axios
      .get(url)
      .then((response) => {
        const vendorData = response.data;

        setId(vendorData.id);
        setName(vendorData.companyName);
        setEmail(vendorData.email);
        setTelephone(vendorData.telephone);
        setAddress(vendorData.address);
        setWhatsapp(vendorData.whatsappNumber);
        setFacebook(vendorData.facebookHandle);
        setPassword(vendorData.password);
        setCurrency(vendorData.currency);
        setLogoFilename(vendorData.logoFilename);
        setBannerFilename(vendorData.bannerFilename);
        localStorage.setItem("companyLogoFilename", vendorData.logoFilename);
        localStorage.setItem("vendorBannerFilename", vendorData.bannerFilename);

        // Store vendor data in localStorage
        localStorage.setItem("companyName", vendorData.companyName);
        localStorage.setItem("companyEmail", vendorData.email);
        localStorage.setItem("companyTelephone", vendorData.telephone);
        localStorage.setItem("companyAddress", vendorData.address);
        localStorage.setItem(
          "companyWhatsappNumber",
          vendorData.whatsappNumber
        );
        localStorage.setItem(
          "companyFacebookHandle",
          vendorData.facebookHandle
        );
        localStorage.setItem("companyPassword", vendorData.password);
        localStorage.setItem("companyCurrency", vendorData.currency);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching vendor data:", error);
      });
  };
  useEffect(() => {
    fetchAccountDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await updateCompanyAccount();
    } catch (error) {
      // Handle errors here
    }

    setLoading(false);
  };
  const updateCompanyAccount = async () => {
    console.log("=====btn is clicked");
    console.log("the id is " + id);
    console.log("the name is " + name);
    console.log("the email is " + email);
    console.log("the telephone is " + telephone);
    console.log("the address is " + address);
    console.log("the whatsap is " + whatsapp);
    console.log("the facebook is " + facebook);
    console.log("the password is " + password);
  
    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/company/${id}`;
  
    const dataToUpdate = {
      companyName: name,
      email: email,
      telephone: telephone,
      address: address,
      whatsappNumber: whatsapp,
      facebookHandle: facebook,
      password: password,
      currency: currency,
    };
  
    try {
      const response = await axios.put(apiUrl, dataToUpdate);
      fetchAccountDetails();
      msgs.current.show([
        {
          sticky: false,
          severity: "success",
          summary: "Success",
          detail: "Account updated successfully",
          closable: false,
        },
      ]);
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Error updating account",
          error,
          closable: false,
        },
      ]);
    }
  };
  
  const updateImages = () => {
    setImageDialog(true);
  };
  const accountBgImage = {
    height: "150px",
    textAlign: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    borderRadius: "12px",
    paddingTop: "120px",
  };

  return (
    <Container>
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <div style={accountBgImage}></div>
      <div className="marginContainer">
        <div className="accountContainer">
          <div className="accountColumn1">
            <Grid container item xs={12} justifyContent="center">
              <Paper style={{ padding: "20px" }}>
                <div className="accountHeader">
                  <Typography>Logo</Typography>
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    alt="Customer"
                    src={logo}
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          </div>
          <div className="accountColumn2">
            <Grid item xs={10}>
              <Paper style={{ padding: "20px" }}>
                <div className="accountHeader">
                  <Typography>Account Details</Typography>
                </div>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-user"></i>
                      </span>
                      <InputText
                        placeholder="Vendor Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-envelope"></i>
                      </span>
                      <InputText
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-phone"></i>
                      </span>
                      <InputText
                        placeholder="Telephone"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-map-marker"></i>
                      </span>
                      <InputText
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-whatsapp"></i>
                      </span>
                      <InputText
                        placeholder="WhatsApp Number"
                        value={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-facebook"></i>
                      </span>
                      <InputText
                        placeholder="Facebook Handle"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="p-inputgroup">
                      <span
                        className="p-inputgroup-addon"
                        style={{
                          backgroundColor: "whitesmoke",
                          color: "green",
                        }}
                      >
                        <i className="pi pi-lock"></i>
                      </span>
                      <InputText
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <div
                          className="card"
                          style={{ position: "absolute", zIndex: "99" }}
                        >
                          {" "}
                          <ProgressSpinner
                            style={{ width: "50px", height: "50px" }}
                            strokeWidth="8"
                            fill="var(--surface-ground)"
                            animationDuration=".5s"
                          />
                        </div>
                      ) : (
                        "Save Changes"
                      )}
                      
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </div>
        </div>
      </div>
    </div>
    </Container>
  );
}

export default Account;
