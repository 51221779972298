import React, { useEffect } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import AOS from "aos";
import Footer from "./Footer";

function Contact() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleCallClick = () => {
    const phoneNumber = "+233598015516";
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div>
      <Header />
      <div className="conatcUsBgImage"></div>
      <div className="foodDeliveryContactUsContainer" id="contact">
        <div
          className="foodDeliveryContactUsBox"
          style={{ marginBottom: "40px" }}
        >
          <div className="foodDeliveryContactUsTitle">
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "60px",
              }}
              data-aos="fade-up"
              className="myTitle"
            >
              Reach Out
            </h1>
            <p data-aos="fade-up">
              Please feel free to contact us at any time. You can reach out to
              us using the following contact information
            </p>
          </div>

          <div class="contact-container">
            <div
              class="contact-box"
              onClick={() => {
                window.open("https://wa.me/+233550270851", "_blank");
              }}
              data-aos="fade-up"
            >
              <div class="foodDeliveryWebsiteIcon">
                <i className="pi pi-whatsapp"></i>
              </div>
              <div class="text">WhatsApp</div>
              <div class="semi-text">+233 550 270 851</div>
            </div>
            <div
              class="contact-box"
              onClick={handleCallClick}
              data-aos="fade-up"
            >
              <div class="foodDeliveryWebsiteIcon">
                <i className="pi pi-phone"></i>
              </div>
              <div class="text">Call Us</div>
              <div class="semi-text">+233 598 015 516</div>
            </div>

            <div class="contact-box" data-aos="fade-up">
              <div class="foodDeliveryWebsiteIcon">
                <i className="pi pi-map-marker"></i>
              </div>
              <div class="text">Visit Us</div>
              <div class="semi-text">Ayikuma Street, Akasanoma Rd</div>
            </div>
          </div>
        </div>
      </div>

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>
    </div>
  );
}

export default Contact;
