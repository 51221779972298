import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";

function ForgotPasswordForm() {
  const msgs = useRef(null);
  const [customerId, setCustomerId] = useState("");
  const [telephone, setTelephone] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await resetPassword();
    } catch (error) {
      // Handle errors here
    }

    setLoading(false);
  };
  const resetPassword = async () => {
    if (newPassword.length > 5) {
      if (newPassword === confirmPassword) {
        try {
          // Perform the API call to retrieve user data based on telephone
          const response = await fetch(
            `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customerByTelephone/1/${telephone}`
          );

          if (!response.ok) {
            throw new Error("Invalid response from the server");
          }
          const customerData = await response.json();

          if (customerData) {
            setCustomerId(customerData.id);
            setFirstname(customerData.firstname);
            setLastname(customerData.lastname);

            handleUpdateCustomerPassword();
          }
        } catch (error) {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Telephone number does not exist !!",
              closable: false,
            },
          ]);
        }
      } else {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Passwords do not match.",
            closable: true,
          },
        ]);
      }
    } else {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Password should be at least 6 characters long.",
          closable: true,
        },
      ]);
    }
  };
  const handleUpdateCustomerPassword = async () => {
    const formData = {
      telephone: telephone,
      firstname: firstname,
      lastname: lastname,
      password: newPassword,
    };
    const response = await axios.put(
      `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customerByTelephone/${customerId}`,
      formData
    );
    if (response.status >= 200) {
      msgs.current.show([
        {
          sticky: false,
          severity: "success",
          summary: "Successs",
          detail: "New password reset successfully !!",
          closable: false,
        },
      ]);
      setTelephone("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Error occured while resetting password",
          closable: false,
        },
      ]);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="foodDeliveryWebsiteCartContainer">
        <div className="messageContainer">
          <div className="card flex justify-content-center">
            <Messages ref={msgs} />
          </div>
        </div>
        <div className="foodDeliveryWebsiteCartContainer">
          <div>
            <div
              style={{
                backgroundColor: "whitesmoke",
                width: "100%",
                padding: "10px",
              }}
            >
              <p style={{ color: "gray" }}>Reset Password</p>
            </div>
            <div
              className="customer-registration-form"
              style={{ padding: "10px" }}
            >
              <form onSubmit={handleSubmit}>
                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-phone"></i>
                  </span>
                  <InputText
                    type="tel"
                    placeholder="Telephone"
                    name="telephone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    required
                  />
                </div>

                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <InputText
                    type="password"
                    placeholder="New Password"
                    name="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <InputText
                    type="password"
                    placeholder="Confirm Password"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                {loading ? (
                  <div
                    className="card"
                    style={{ position: "absolute", zIndex: "99" }}
                  >
                    {" "}
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    severity="warning"
                    label="Reset Password"
                    type="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "#ff007f",
                      border: "none",
                    }}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
