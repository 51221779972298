import React, { useEffect, useState } from "react";
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import Container from "../Container";
import Customers from "./Customers";

function CustomerOrderDetails() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [orders, setOrders] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [customerFilename, setCustomerFilename] = useState("");
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );

  console.log("helo world from customer details");
  const fetchCustomerOrders = () => {
    setCustomerFilename(localStorage.getItem("customerFilename"));

    axios
      .get(
        `${
          global.server
        }/api/foodDelivery/orderByCustomerId/${localStorage.getItem(
          "customerId"
        )}`
      )
      .then((response) => {
        const orders = response.data;
        setOrders(orders);

        // Calculate the total quantity
        const totalQuantity = orders.reduce((accumulator, order) => {
          return accumulator + order.quantity;
        }, 0);
        setTotalQuantity(totalQuantity);

        // Calculate the total amount
        const totalAmount = orders.reduce((accumulator, order) => {
          return accumulator + order.total;
        }, 0);
        setTotalAmount(totalAmount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <Container>
        <div className="customerMainContainer">
          <div className="customerMainColumn1">
            <Grid container spacing={4} sx={{ mb: 2 }}>
              <Grid item xs={2}>
                <Tooltip title={localStorage.getItem("customerName")}>
                  <Avatar
                    alt="Customer Image"
                    src={`../../../uploadImages/${customerFilename}`}
                    style={{
                      width: "40px",
                      height: "40px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs sx={{ marginTop: "20px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Typography variant="h7">
                      Nubmber of orders -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {orders.length}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h7">
                      Qty ordered -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {totalQuantity}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h7">
                      Amount -{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {currency}
                        {totalAmount.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div>
              <TableContainer component={Paper} className="responsive-table">
                <Table>
                  <TableHead style={{ backgroundColor: "whitesmoke" }}>
                    <TableRow>
                      <TableCell>Order Number</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Delivery To</TableCell>
                      <TableCell>Time Ordered</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order) => (
                        <TableRow key={order.id}>
                          <TableCell>{order.orderNumber}</TableCell>
                          <TableCell>{order.foodDeliveryMenu.foodName}</TableCell>
                          <TableCell>
                            <img
                              alt="Customer"
                              src={`../../../uploadImages/${order.foodDeliveryMenu.filename}`}
                              style={{
                                width: "100px",
                                height: "60px",
                                borderRadius: "10px",
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {currency}
                            {order.price.toLocaleString("en-GH", {
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>{order.quantity}</TableCell>
                          <TableCell>
                            {currency}
                            {order.total.toLocaleString("en-GH", {
                              minimumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell>{order.deliveryTo}</TableCell>
                          <TableCell>
                            {" "}
                            {new Date(order.orderedDate).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[4, 8, 12]}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
          </div>
          <div className="customerMainColumn2">
            <Customers fetchCustomerOrders={fetchCustomerOrders} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CustomerOrderDetails;
