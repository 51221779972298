import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { Messages } from "primereact/messages";
import { Phone, Refresh } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import EditCategory from "./EditCategory";
import { Badge } from "primereact/badge";

function Categories() {
  const msgs = useRef(null);
  const [categoryDialog, setCategoryDialog] = useState(false);
  const [foodCategories, setFoodCategories] = useState([]);

  const fetchCategories = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/categories/${localStorage.getItem(
          "foodDeliveryCompanyId"
        )}`
      )
      .then((response) => {
        setFoodCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const deleteCategory = (id) => {
    console.log(`Button clicked for category with id: ${id}`);

    axios
      .delete(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/category/${id}`)
      .then((response) => {
        if (response.status >= 204) {
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Category deleted successfully.",
              closable: false,
            },
          ]);

          fetchCategories();
        } else {
          console.error(`Failed to delete category with id ${id}.`);
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };

  const editCategory = (id, foodCategory,filename) => {
    localStorage.setItem("foodCategoryId", id);
    localStorage.setItem("foodCategory", foodCategory);
    localStorage.setItem("imageNameCategory", filename);
    setCategoryDialog(true);
  };
  return (
    <div>
      <div className="card flex justify-content-center">
        <Messages ref={msgs} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Categories
          <Badge
            style={{ marginLeft: "3px" }}
            value={foodCategories.length}
            severity="success"
          ></Badge>
        </Typography>

        <Tooltip title="Reload food categories">
          <Refresh
            className="icon"
            style={{ fontSize: "22px", marginRight: "10px" }}
            onClick={fetchCategories}
          />
        </Tooltip>
      </div>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table>
          <TableHead style={{ backgroundColor: "whitesmoke" }}>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {foodCategories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>
                  <img
                    alt="Customer"
                    src={require(`../../../categoryUploadImages/${category.filename}`)}
                    style={{
                      width: "130px",
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                </TableCell>

                <TableCell>{category.foodCategory}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      editCategory(category.id, category.foodCategory,category.filename)
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    type="submit"
                    color="error"
                    onClick={() => deleteCategory(category.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        header="Update"
        visible={categoryDialog}
        style={{ width: "50vw" }}
        onHide={() => setCategoryDialog(false)}
      >
        <EditCategory />
      </Dialog>
    </div>
  );
}

export default Categories;
