import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Avatar,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function PrintSingleOrder() {
  const [orderNumber, setOrderNumber] = useState("");
  const [customerFirstname, setCustomerFirstname] = useState("");
  const [customerLastname, setCustomerLastname] = useState("");
  const [foodName, setFoodName] = useState("");
  const [foodFilename, setFoodFilename] = useState("");
  const [currency, setCurrency] = useState("");

  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [dispatchRider, setDispatchRider] = useState("");
  const [timeOrdered, setTimeOrdered] = useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const findOrderByOrderId = () => {
    const apiUrl = `${
      global.server
    }/api/foodDelivery/orders/${localStorage.getItem("orderId")}`;
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setOrderNumber(data.orderNumber);
        setUnitPrice(data.price);
        setQuantity(data.quantity);
        setGrandTotal(data.total);
        setDeliveryTo(data.deliveryTo);
        setCustomerFirstname(data.foodDeliveryCustomer.firstname);
        setCustomerLastname(data.foodDeliveryCustomer.lastname);
        setCurrency(data.foodDeliveryMenu.company.currency);
        setFoodName(data.foodDeliveryMenu.foodName);
        setTimeOrdered(data.orderedDate);
        setDispatchRider(data.foodDeliveryDispatchRider.riderName);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    findOrderByOrderId();
  }, []);

  const printReceipt = () => {
    window.print();
  };
  return (
    <div>
      <div className="printSingleOrderContainer">
        <div style={{ textAlign: "right", marginBottom: "15px" }}>
          <Button variant="contained" color="success" onClick={printReceipt}>
            Print
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "100%" }}
            showGridlines
            aria-label="customized table"
          >
            <TableHead showGridlines>
              <TableRow showGridlines>
                <StyledTableCell>Order Receipt {orderNumber}</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Order Number
                </StyledTableCell>
                <StyledTableCell align="right">{orderNumber}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Customer
                </StyledTableCell>
                <StyledTableCell align="right">
                  {customerFirstname} {customerLastname}
                </StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Product Ordered
                </StyledTableCell>
                <StyledTableCell align="right">{foodName}</StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Unit Price
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  {currency}
                  {unitPrice.toLocaleString("en-GH", {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Qty Ordered
                </StyledTableCell>
                <StyledTableCell align="right">{quantity}</StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Total Price
                </StyledTableCell>
                <StyledTableCell align="right">
                  {currency}
                  {grandTotal.toLocaleString("en-GH", {
                    minimumFractionDigits: 2,
                  })}
                </StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Delivered To
                </StyledTableCell>
                <StyledTableCell align="right">{deliveryTo}</StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Dispatch Rider
                </StyledTableCell>
                <StyledTableCell align="right">{dispatchRider}</StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Time Ordered
                </StyledTableCell>
                <StyledTableCell align="right">{timeOrdered}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default PrintSingleOrder;
