import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Messages } from "primereact/messages";
import { Refresh } from "@mui/icons-material";
import { Dialog } from "primereact/dialog";
import EditWorkingDays from "./EditWorkingDays";
import { Badge } from "primereact/badge";

function WorkingDays() {
  const msgs = useRef(null);
  const [brands, setWorkingDays] = useState([]);
  const [brandsDialog, setWorkingDaysDialog] = useState(false);

  const fetchWorkingDays = () => {
    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/brand/1`)
      .then((response) => {
        setWorkingDays(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchWorkingDays();
  }, []);

  const deleteWorkingDay = (id) => {
    console.log(`Button clicked for working day with id: ${id}`);
    axios
      .delete(`https://reminiscent-swim-production.up.railway.app/api/brand/${id}`)
      .then((response) => {
        if (response.status >= 204) {
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "brand deleted successfully.",
              closable: false,
            },
          ]);

          fetchWorkingDays();
        } else {
          console.error(`Failed to deletea brand with id ${id}.`);
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };
  const editBrands = (id, brandName) => {
    localStorage.setItem("brandId", id);
    localStorage.setItem("brandName", brandName);
    setWorkingDaysDialog(true);
  };
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Brands{" "}
          <Badge
            style={{ marginLeft: "3px" }}
            value={brands.length}
            severity="success"
          ></Badge>
        </Typography>
        <Tooltip title="Reload working days">
          <Refresh
            className="icon"
            style={{ fontSize: "22px", marginRight: "10px" }}
            onClick={fetchWorkingDays}
          />
        </Tooltip>
      </div>
      <TableContainer component={Paper} style={{ width: "100%" }}>
        <Table>
          <TableHead style={{ backgroundColor: "whitesmoke" }}>
            <TableRow>
              <TableCell>Brand Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brands.map((brand) => (
              <TableRow>
                <TableCell>{brand.brandName}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      editBrands(
                        brand.id,
                        brand.brandName
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteWorkingDay(brand.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        header="Update Brand"
        visible={brandsDialog}
        style={{ width: "50vw" }}
        onHide={() => setWorkingDaysDialog(false)}
      >
        <EditWorkingDays />
      </Dialog>
    </div>
  );
}

export default WorkingDays;
