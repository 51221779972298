import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  colors,
  Typography,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Badge } from "primereact/badge";

function Orders() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [orders, setOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );
  const [countNewOrders, setCountNewOrders] = useState(0);
  const [countProcessingOrders, setProcessingNewOrders] = useState(0);
  const [countShippedOrders, setShippedNewOrders] = useState(0);
  const [countDeliveredOrders, setDeliveredNewOrders] = useState(0);

  const retriveOrderDetails = () => {
    const url = `${
      global.server
    }/api/foodDelivery/countNewOrders/${localStorage.getItem("foodDeliveryCompanyId")}`;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        setCountNewOrders(data.countNewOrders);
        setProcessingNewOrders(data.countProcessingOrders);
        setShippedNewOrders(data.countShippedOrders);
        setDeliveredNewOrders(data.countNewOrders);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching monthly sales data:", error);
      });
  };
  const fetchOrders = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/vendor/orders/${localStorage.getItem(
          "foodDeliveryCompanyId"
        )}`
      )
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    retriveOrderDetails();
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const moveToViewOrder = (id) => {
    console.log("move to view order button is clicked");
    console.log("the order is is ", id);
    localStorage.setItem("orderId", id);
    navigate("/viewOrder");
  };

  const retrieveOrderByOrderedDate = (date) => {
    setSelectedDate(date.value);
    if (date.value) {
      const formattedDate = new Date(date.value).toISOString().split("T")[0];
      console.log(formattedDate);
      axios
        .get(
          `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orderDate/${localStorage.getItem(
            "foodDeliveryCompanyId"
          )}?orderedDate=${formattedDate}`
        )
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  const searchByOrderNumber = () => {
    console.log("order number:", orderNumber);
    axios
      .get(
        `${
          global.server
        }/api/foodDelivery/orderNumberLike/${localStorage.getItem(
          "foodDeliveryCompanyId"
        )}/${orderNumber}`
      )
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs="auto">
                <Typography variant="p" style={{ fontSize: "12px" }}>
                  My Orders
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={orders.length}
                    severity="success"
                  ></Badge>{" "}
                  New
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={countNewOrders}
                    severity="danger"
                  ></Badge>{" "}
                  Processing
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={countProcessingOrders}
                    severity="danger"
                  ></Badge>{" "}
                  Shipped
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={countShippedOrders}
                    severity="danger"
                  ></Badge>{" "}
                  Delivered
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={countDeliveredOrders}
                    severity="danger"
                  ></Badge>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <div
              className="search-inputW"
              style={{ border: "2px solid transparent" }}
            >
              <span className="p-input-icon-right">
                <Tooltip title="Search by order number">
                  <i
                    className="pi pi-search"
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={searchByOrderNumber}
                  />
                </Tooltip>
                <InputText
                  placeholder="Search by order number"
                  style={{ width: "100%" }}
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
              </span>
            </div>
          </Grid>
          <Grid item xs={3}>
            {" "}
            <div className="date-picker">
              <Calendar
                placeholder="Search by order date"
                dateFormat="mm/dd/yy"
                style={{ width: "100%", marginBottom: "10px" }}
                selected={selectedDate}
                onChange={retrieveOrderByOrderedDate}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <TableContainer component={Paper} className="responsive-table">
          <Table style={{ border: "1px solid #e0e0e0" }}>
            <TableHead style={{ backgroundColor: "whitesmoke" }}>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>Order Number</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Delivery To</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Time Ordered</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => (
                  <TableRow key={order.id}>
                    <TableCell style={{ border: "1px solid #e0e0e0" }}>
                      <Avatar
                        alt="Customer Image"
                        src={`../../../uploadImages/${order.foodDeliveryCustomer.filename}`}
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      />
                      {order.foodDeliveryCustomer.firstname}{" "}
                      {order.foodDeliveryCustomer.lastname}
                    </TableCell>
                    <TableCell style={{ border: "1px solid #e0e0e0" }}>
                      {order.orderNumber}
                    </TableCell>
                    <TableCell style={{ border: "1px solid #e0e0e0" }}>
                      {order.foodDeliveryMenu.foodName}
                    </TableCell>
                    <TableCell style={{ border: "1px solid #e0e0e0" }}>
                      <img
                        alt="foodDeliveryCustomer"
                        src={require(`../../../uploadImages/${order.foodDeliveryMenu.filename}`)}
                        style={{
                          width: "100px",
                          height: "60px",
                          borderRadius: "10px",
                        }}
                      />
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {currency}
                      {order.price.toLocaleString("en-GH", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {order.quantity}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {currency}
                      {order.total.toLocaleString("en-GH", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {order.deliveryTo}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {order.source}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {order.status}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      {new Date(order.newOrderDate).toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell className="tableCellBorder">
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => moveToViewOrder(order.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </div>
    </div>
  );
}

export default Orders;
