import React, { useEffect, useRef, useState } from "react";
import Container from "./Container";

import { Button } from "primereact/button";
import { useGlobalState } from "./state/VendorState";
import { Avatar, Chip } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  Email,
  FacebookRounded,
  Mail,
  Phone,
  WhatsApp,
  WhatshotRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FirstChart from "./Dashboard/FirstChart";
import logo from "../../images/ccLogo.png";

function Dashboard() {
  const navigate = useNavigate();
  const [companyName, setcompanyName] = useState(
    localStorage.getItem("companyName")
  );
  const [companyLogoFilename, setcompanyLogoFilename] = useState(
    localStorage.getItem("companyLogoFilename")
  );
  const [email, setEmail] = useState(localStorage.getItem("companyEmail"));
  const [telephone, setTelephone] = useState(
    localStorage.getItem("companyTelephone")
  );
  const [address, setAddress] = useState(
    localStorage.getItem("companyAddress")
  );
  const [whatsapp, setWhatsapp] = useState(
    localStorage.getItem("companyWhatsappNumber")
  );
  const [facebook, setFacebook] = useState(
    localStorage.getItem("companyFacebookHandle")
  );
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );
  const [monthlySales, setmonthlySales] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  const retriveMonthlySales = () => {
    const url = `${
      global.server
    }/api/foodDelivery/ordersMonthlySales/${localStorage.getItem(
      "foodDeliveryCompanyId"
    )}`;
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        setmonthlySales(data.monthlySales);
        setStartDate(data.firstDayOfTheMonth);
        setEndDate(data.lastDayOfTheMonth);
        setCurrentMonth(data.monthName);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching monthly sales data:", error);
      });
  };

  useEffect(() => {
    retriveMonthlySales();
  }, []);

  return (
    <div>
      <Container>
        <div className="dashboardContainer">
          <div className="dashboardColumn1">
            <div className="monthlySalesContainer">
              <div className="monthlySalesColumn1">
                <div>
                  <span style={{ color: "rgb(107, 105, 105)" }}>
                    Monthly sales
                  </span>
                </div>
                <div>
                  <span
                    style={{ fontSize: 20, marginTop: 5, fontWeight: "bold" }}
                  >
                    GHS{""}
                    {monthlySales.toLocaleString("en-GH", {
                      minimumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: 12, marginTop: 5 }}>
                    The current sales amount is between
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: 12, marginTop: 5 }}>
                    {startDate} - {endDate}
                  </span>
                </div>
              </div>

              <div className="monthlySalesColumn2">
                <h1>{currentMonth}</h1>
              </div>
            </div>
            <div className="gridBoxContainer">
              <div className="gridBox">
                <FirstChart />
              </div>
            </div>
          </div>
          <div className="dashboardColumn2">
            <div style={{ marginTop: 30 }}>
              <span style={{ fontWeight: "bold" }}>My Account</span>
            </div>
            <div>
              <img className="App-logo"
                src={logo}
                style={{
                  width: "80px",
                  height: "80px",
                  marginTop: 5,
                  marginBottom: 20,
                  borderRadius: "50%",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
            <span style={{ fontWeight: "bold" }}>{companyName}</span>
            <div>
              <span style={{ fontSize: 13 }}>
                Active Account{" "}
                <i
                  className="pi pi-star-fill"
                  style={{ fontSize: "15px", color: "yellowgreen" }}
                ></i>
              </span>
            </div>
            <div>
              <Button
                icon="pi pi-globe pi-spin"
                label="View Business Website"
                severity="success"
                outlined
                size="small"
                onClick={() => {
                  window.open("https://www.countclubgh.com", "_blank");
                }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <div>
                <Chip
                  style={{ margin: 7 }}
                  avatar={<FacebookRounded style={{ color: "royalblue" }} />}
                  label={facebook}
                  variant="outlined"
                />
                <Chip
                  style={{ margin: 7 }}
                  avatar={<WhatsApp style={{ color: "green" }} />}
                  label={whatsapp}
                  variant="outlined"
                />
                <Chip
                  style={{ margin: 7 }}
                  avatar={<Phone style={{ color: "grey" }} />}
                  label={telephone}
                  variant="outlined"
                />
                <Chip
                  style={{ margin: 7 }}
                  avatar={<FmdGoodIcon style={{ color: "maroon" }} />}
                  label={address}
                  variant="outlined"
                />
                <Chip
                  style={{ margin: 7 }}
                  avatar={<Mail style={{ color: "maroon" }} />}
                  label={email}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Dashboard;
