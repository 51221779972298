import React from "react";
import ReviewDetails from "./review/ReviewDetails";
import Container from "./Container";

function Reviews() {
  return (
    <div>
      <Container>
      <ReviewDetails />
      </Container>
    </div>
  );
}

export default Reviews;
