import React, { useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { AutoAwesomeMosaic, Phone, SearchRounded } from "@mui/icons-material";
import ProductsIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from "react-router-dom";

function BottomNavBar() {
  const [value, setValue] = useState(localStorage.getItem("value"));
  const navigate = useNavigate();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const determineActiveColor = (activeValue) => {
    switch (activeValue) {
      case "1":
        return "#ff007f"; 
      case "2":
        return "#ff007f";
      case "3":
        return "#ff007f";
      case "4":
        return "#ff007f";
      case "5":
        return "#ff007f";
    }
  };

  const activeIconColor = {
    color: determineActiveColor(value),
  };
  const moveToHome = () => {
    localStorage.setItem("value", "1");
    navigate("/");
  };
  const moveToAllProducts = () => {
    localStorage.setItem("value", "2");
    navigate("/allProducts");
  };

  const moveToSearch = () => {
    localStorage.setItem("value", "3");
    navigate("/search");
  };
  const moveToCustomerOrders = () => {
    localStorage.setItem("value", "4");
    navigate("/customerOrders");
  };
  const moveToContactUs = () => {
    localStorage.setItem("value", "5");
    navigate("/contact-us");
  };
  useEffect(() => {
    determineActiveColor();
  },);

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        boxShadow: "0px -5px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <BottomNavigationAction
        label="Home"
        icon={<HomeIcon />}
        onClick={moveToHome}
        style={value === "1" ? activeIconColor : {}}
      />
      <BottomNavigationAction
        label="Products"
        icon={<AutoAwesomeMosaic />}
        onClick={moveToAllProducts}
        style={value === "2" ? activeIconColor : {}}
      />
      <BottomNavigationAction
        label="Search"
        onClick={moveToSearch}
        icon={<SearchRounded />}
        style={value === "3" ? activeIconColor : {}}
      />
      <BottomNavigationAction
        label="Orders"
        icon={<ProductsIcon />}
        onClick={moveToCustomerOrders}
        style={value === "4" ? activeIconColor : {}}
      />
      <BottomNavigationAction
        label="Contact"
        icon={<Phone />}
        onClick={moveToContactUs}
        style={value === "5" ? activeIconColor : {}}
      />
    </BottomNavigation>
  );
}

export default BottomNavBar;
