import React from "react";
import Menus from "./menu/Menus";

function Menu() {
  return (
    <div>
      <Menus />
    </div>
  );
}

export default Menu;
