import axios from "axios";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import React, { useRef, useState } from "react";

function ShippedStep(props) {
  const [id, setId] = useState(localStorage.getItem("orderId"));
  const msgs = useRef(null);
  const deliverOrder = () => {
    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/deliverOrder/${id}`;

    const dataToUpdate = {
      id: id,
    };
    axios
      .put(apiUrl, dataToUpdate)
      .then((response) => {
        props.fetchOrderDetails();
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Successs",
            detail: "Order delivered successfully",
            closable: false,
          },
        ]);
      })
      .catch((error) => {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Error delivering order",
            error,
            closable: false,
          },
        ]);
      });
  };
  return (
    <div>
      <div className="card flex justify-content-center">
        <Messages ref={msgs} />
      </div>
      <h1>Shipped Item</h1>
      <Button
        label="Item Delivered"
        icon="pi pi-shopping-cart"
        severity="success"
        size="small"
        onClick={deliverOrder}
        style={{ width: "100%", marginTop: "10px" }}
      />
    </div>
  );
}

export default ShippedStep;
