import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

import { Tooltip } from "@mui/material";
import { Button } from "primereact/button";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import Header from "../../company/Header";
import BottomNavBAr from "../../company/BottomNavBar";
import AOS from "aos";

function AllProducts() {
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [source, setSource] = useState(localStorage.getItem("source"));
  const [categoryId, setCategoryId] = useState(
    localStorage.getItem("categoryId")
  );
  const [allMenus, setAllMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  let apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/fetchWebsiteAllMenus/1`;
  const fetchAllMenus = () => {
    axios
      .get(apiEndpoint)
      .then((response) => {
        setAllMenus(response.data);
        setIsLoading(false); // Set loading to false once data is fetched
        setLoading(false);
        setSearchVisible(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Set loading to false in case of an error
      });
  };

  useEffect(() => {
    handleSourceChange();
  }, []);

  const handleSourceChange = () => {
    // Conditionally call a function based on the value of source
    switch (source) {
      case "products":
        fetchAllMenus();
        break;
      case "category":
        fetchAllMenusFunctionByCategory();
        break;
      case "searchFromNav":
        searchFromNav();
        break;
      // Add more cases as needed

      default:
        // Default case or handle unknown source
        break;
    }
  };
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);



  const fetchAllMenusFunctionByCategory = () => {
    apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menuByCategory/${categoryId}`;
    fetchAllMenus();
  };

  /**FETCH ALL MENUS WHEN THE VIEW ALL MENUS LINK IS CLICKED */
  const fetchAllMenusByLink = () => {
    setSearchText("");
    setLoading(true);
    apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/fetchWebsiteAllMenus/1`;

    axios
      .get(apiEndpoint)
      .then((response) => {
        setAllMenus(response.data);
        setLoading(false);
        setSearchVisible(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const addToCart = (
    foodId,
    foodName,
    foodPrice,
    foodDescription,
    foodImage,
    brandName
  ) => {
    localStorage.setItem("cartFoodId", foodId);
    localStorage.setItem("cartFoodName", foodName);
    localStorage.setItem("cartFoodPrice", foodPrice);
    localStorage.setItem("cartFoodDescription", foodDescription);
    localStorage.setItem("cartFoodImage", foodImage);
    localStorage.setItem("priceForCalculations", foodPrice);
    localStorage.setItem("brandName", brandName);
    navigate("/orderNow");
  };
  const search = async () => {
    apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menu/foodName/1/${searchText}`;
    fetchAllMenus();
  };
  const searchFromNav = async () => {
    apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menu/foodName/1/${localStorage.getItem(
      "searchText"
    )}`;
    fetchAllMenus();
  };

  const searchByFoodName = async (e) => {
    e.preventDefault();

    if (!searchText == "") {
      try {
        setLoading(true);
        await search();
      } catch (error) {}
    }
  };

  const hideSearchDialog = () => {
    setLoading(false);
  };
  return (
    <div>
      <Header />
      <div className="searchBgImage">
        <div className="searchContainer">
          <div>
            <div
              style={{
                justifyContent: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontFamily: "monospace",
                marginTop: "40px",
              }}
              className="myTitle"
            >
              <p>Filter Products</p>
            </div>

            <div>
              <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                <InputText
                  placeholder="Search for product"
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "whitesmoke",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  required
                />
                <Tooltip title="Search">
                  <span className="p-inputgroup-addon" style={{backgroundColor:"#ff007f",cursor: "pointer",color:"white"}}>
                    <i
                      className="pi pi-search"
                      onClick={searchByFoodName}
                    ></i>
                  </span>
                </Tooltip>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  raised
                  style={{
                    backgroundColor: "gold",
                    border: "none",
                    color: "#fff",
                    width: "100%",
                  }}
                  label={"View All products"}
                  onClick={fetchAllMenusByLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "10px",
          marginTop: "40px",
          textAlign: "center",
          marginBottom: "60px",
        }}
      >
        {isLoading ? (
          // Display skeleton or loading indicator while data is fetching
          <div
            className="skeleton-container"
            style={{ color: "red", textAlign: "center" }}
          >
            <span>Loading... </span>
            {/* Your skeleton loading component/style goes here */}
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4">
              {/* Skeleton Loader Item */}
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-5 ">
              <span style={{ color: "#ff007f" }}>{allMenus.length}</span>{" "}
              <span className="myTitle">Products</span>
            </h2>
            <div className="grid-container">
              {allMenus.map((menu) => (
                <div
                  className="grid-item"
                  onClick={() =>
                    addToCart(
                      menu.id,
                      menu.foodName,
                      menu.price,
                      menu.foodDescription,
                      menu.filename,
                      menu.brand.brandName
                    )
                  }
                >
                  <img
                    src={require(`../../../uploadImages/${menu.filename}`)}
                    alt=""
                    style={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "5px",
                    }}
                  />
                  <div style={{ textAlign: "left", padding: "7px" }}>
                    <p style={{ fontSize: "12px" }}>
                      {menu.foodName} -{" "}
                      <span style={{ fontWeight: "bold", color: "#ff007f" }}>
                        {" "}
                        GHS {menu.price}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/**FOOTER 
      <footer className="footer">
        <Footer />
      </footer>*/}

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>

      {/**SEARCH BUTTON
      <div
        style={{ position: "fixed", right: "20px", bottom: "95px" }}
        className="mobileVisibility"
      >
        <Tooltip title="Search for a product" placement="left">
          <Button
            raised
            rounded
            icon="pi pi-search"
            style={{ backgroundColor: "#ff007f", border: "none" }}
            aria-label="Notification"
            onClick={() => setSearchVisible(true)}
            title="Search for a product"
          />
        </Tooltip>{" "}
      </div> */}

      {/** SEARCH DIALOG*/}
      <Dialog
        header="please wait"
        visible={loading}
        position={"center"}
        onHide={hideSearchDialog}
      >
        <div className="card" style={{ position: "absolute", zIndex: "99" }}>
          {" "}
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div
              className="bg-white p-8 rounded shadow-md items-center"
              style={{ textAlign: "center" }}
            >
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />

              <p>Searching for products, please wait...</p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AllProducts;
