import React, { useEffect } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import { TabView, TabPanel } from "primereact/tabview";
import FoodDeliveryAddCustomer from "./FoodDeliveryAddCustomer";
import ForgotPasswordForm from "./ForgotPasswordForm";
import CustomerLogin from "./CustomerLogin";
import AOS from "aos";

function UserAccount() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="customerAccountBgImage">
      <Header />
      <div className="customerAccountContainer">
        <div>
          <div
            style={{
              textAlign: "center",
              color: "#373838",
              marginBottom: "10px",
            }}
          >
            <h1
              style={{ fontWeight: "bold", fontSize: "40px" }}
              data-aos="fade-up"
              className="myTitle"
            >
              My Account
            </h1>
          </div>
        </div>
        <div>
          <div>
            <TabView>
              <TabPanel header="Login">
                <CustomerLogin />
              </TabPanel>
              <TabPanel header="Sign Up">
                <FoodDeliveryAddCustomer />
              </TabPanel>
              <TabPanel header="Forgot Password">
                <ForgotPasswordForm />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>
    </div>
  );
}

export default UserAccount;
