import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Form } from "react-router-dom";
import axios from "axios";
import { Messages } from "primereact/messages";

function AddWorkingDays() {
  const msgs = useRef(null);
  const [brandName, setBrandName] = useState("");
  const[company,setCompany]=useState("1");

  const handleAddBrand = async (e) => {
    e.preventDefault();
  
    try {
      // First, get the list of working days
      const brandResponse = await axios.get(
        `https://reminiscent-swim-production.up.railway.app/api/brand/${company}`
      );
  
      if (brandResponse.status === 200) {
        const existingBrandName = brandResponse.data;
        
        // Check if the working day already exists in the list
        const exists = existingBrandName.some(day => day.brandName === brandName);
  
        if (exists) {
          // If the working day already exists, display an error message
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: brandName+ " already exists.",
              closable: false,
            },
          ]);
        } else {
          // If the working day doesn't exist, proceed to add it
          const formData = {
            brandName,
            company: { id: company },
          };
  
          const response = await axios.post(
            `https://reminiscent-swim-production.up.railway.app/api/brand`,
            formData
          );
  
          if (response.status >= 200) {
            setBrandName("");
            msgs.current.show([
              {
                sticky: false,
                severity: "success",
                summary: "Success",
                detail:  brandName+ " added successfully.",
                closable: false,
              },
            ]);
          } else {
            msgs.current.show([
              {
                sticky: false,
                severity: "error",
                summary: "Error",
                detail: "Failed to add brand.",
                closable: false,
              },
            ]);
          }
        }
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          closable: false,
        },
      ]);
    }
  };
  
  return (
    <div>
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
      <form className="form"  onSubmit={handleAddBrand}
         >
          <TextField
              label="Brand Name"
              variant="outlined"
              value={brandName} style={{marginTop:"35px"}}
              onChange={(e) => setBrandName(e.target.value)}
              fullWidth
              required
            />
          
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="success"
          className="submit"
          style={{marginTop:"10px"}}
        >
          Add Brand
        </Button>
      </form>
    </div>
  );
}

export default AddWorkingDays;
