import React, { useEffect, useState } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import { useNavigate } from "react-router-dom";
import TagIcon from "@mui/icons-material/Tag";
import axios from "axios";
import SearchContainer from "./SearchContainer";
import AOS from "aos";

function Search() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchCategories();
  }, []);
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);
  const fetchCategories = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/distinctCategories`
      )
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const fetchAllMenusFunctionByCategory = (id) => {
    localStorage.setItem("source", "category");
    localStorage.setItem("categoryId", id);
    navigate("/allProducts");
  };
  return (
    <div>
      <Header />
      <SearchContainer />

      <div
        style={{
          justifyContent: "center",
          marginBottom: "20px",
          fontWeight: "bold",
          fontFamily: "monospace",
          marginTop: "40px",
          marginLeft: "10px",
          fontSize: "30px",
        }}
        className="myTitle"
      >
        <p>
          {" "}
          <TagIcon color="primary" fontSize="large" />
          Categories
        </p>
      </div>
      <div className="flexbox-container" style={{ marginBottom: "70px" }}>
        {categories.map((category) => (
          <div
            key={category.id}
            className="flexbox-item"
            onClick={() => fetchAllMenusFunctionByCategory(category.id)}
          >
            <img
              src={require(`../../categoryUploadImages/${category.filename}`)}
              className="h-full w-full object-cover object-center"
            />
            <div
              style={{
                border: "2px solid red",
                width: "100%",
                overflow: "hidden",
                display: "none",
              }}
            >
              <span
                style={{
                  marginTop: "-20px",
                  position: "absolute",
                  color: "#fff",
                  borderRadius: "6px",
                  backgroundColor: "#f806a7",
                  padding: "2px",
                  fontSize: "12px",
                  maxWidth: "50%",
                }}
              >
                {" "}
                {category.foodCategory}
              </span>
            </div>
          </div>
        ))}
      </div>
      
      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div> 
    </div>
  );
}

export default Search;
