import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import { TroubleshootRounded } from "@mui/icons-material";

function FoodDeliveryAddCustomer() {
  const msgs = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let foodDeliveryCompanyId = "1";

  const registerCustomer = async () => {
    const formData = {
      firstname: firstName,
      lastname: lastName,
      telephone: telephone,
      password: password,
      company: { id: foodDeliveryCompanyId },
    };
    try {
      const response = await axios.post(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customers`,
        formData
      );
      if (response.status >= 200) {
        setFirstName("");
        setLastName("");
        setTelephone("");
        setPassword("");
        msgs.current.show([
          {
            sticky: true,
            severity: "success",
            summary: "",
            detail:
              "Account registered successfully.",
            closable: true,
          },
        ]);
      } else {
        msgs.current.show([
          {
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: "Failed to register account.",
            closable: true,
          },
        ]);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          closable: false,
        },
      ]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const telephoneRegex = /^\d{10}$/; // Regular expression for 10-digit telephone number
    if (!telephoneRegex.test(telephone)) {
      msgs.current.show([
        {
          sticky: true,
          severity: "error",
          summary: "Error",
          detail: "Telephone number should be 10 digits.",
          closable: true,
        },
      ]);
      return;
    }

    if (password.length < 6) {
      msgs.current.show([
        {
          sticky: true,
          severity: "error",
          summary: "Error",
          detail: "Password should be at least 6 characters long.",
          closable: true,
        },
      ]);
      return;
    }

    try {
      // First, check if the customer is registered in the company name
      const customersResponse = await axios.get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customers/${foodDeliveryCompanyId}`
      );

      if (customersResponse.status === 200) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          const existingCustomers = customersResponse.data;

          const exists = existingCustomers.some(
            (customer) => customer.telephone === telephone
          );
          if (exists) {
            msgs.current.show([
              {
                sticky: TroubleshootRounded,
                severity: "error",
                summary: "Error",
                detail: "Telephone number already registered.",
                closable: true,
              },
            ]);
          } else {
            registerCustomer();
          }
        }, 2000);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error.response.data,
          closable: false,
        },
      ]);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="foodDeliveryWebsiteCartContainer">
        <div className="messageContainer">
          <div className="card flex justify-content-center">
            <Messages ref={msgs} />
          </div>
        </div>
        <div className="foodDeliveryWebsiteCartContainer">
          <div>
            <div
              style={{
                backgroundColor: "whitesmoke",
                width: "100%",
                padding: "15px",
              }}
            >
              <p style={{ color: "gray" }}>Registration Form</p>
            </div>
            <div
              className="customer-registration-form"
              style={{ padding: "10px" }}
            >
              <form onSubmit={handleSubmit}>
                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>

                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>

                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-phone"></i>
                  </span>
                  <InputText
                    type="tel"
                    placeholder="Telephone"
                    name="telephone"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    required
                  />
                </div>

                <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-lock"></i>
                  </span>
                  <InputText
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                {loading ? (
                  <div
                    className="card"
                    style={{ position: "absolute", zIndex: "99" }}
                  >
                    {" "}
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    severity="warning"
                    label="Sign up"
                    type="submit"
                    style={{
                      width: "100%",
                      backgroundColor: "#ff007f",
                      border: "none",
                    }}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodDeliveryAddCustomer;
