import React from "react";
import Contact from "./Contact";
import Footer from "./Footer";

function ContactUS() {
  return (
    <div>
      <Contact />
      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
}
export default ContactUS;
