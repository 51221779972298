import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function DeliveredStep() {
  const navigate = useNavigate();

 const openReceiptTab = () => {
    const url =  "/fooddelivery/printSingleOrder";
    window.open(url, '_blank');
  }

  return (
    <div>
      <h1>Item Delivered</h1>
      <Button
        variant="contained"
        color="success"
        onClick={openReceiptTab}
      >
        View Receipt
      </Button>
    </div>
  );
}

export default DeliveredStep;
