import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";

import { Grid, IconButton, Rating, Tooltip, InputLabel } from "@mui/material";
import { Button } from "primereact/button";
import { Toolbar, FormControl, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { Messages } from "primereact/messages";
import { usePaystackPayment } from "react-paystack";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Footer from "../../company/Footer";
import Header from "../../company/Header";
import BottomNavBAr from "../../company/BottomNavBar";
import Testimonies from "../../company/Testimonies";
import Contact from "../../company/Contact";
import Payment from "../../company/Payment";

function FoodDeliveryWebsite(props) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const msgs = useRef(null);

  const [scrolling, setScrolling] = useState(false);
  const [visible, setVisible] = useState(false);
  const [customerLoginVisible, setCustomerLoginVisible] = useState(false);
  const [position, setPosition] = useState("center");
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quantity, setCount] = useState(0);
  const [cartFoodPrice, setCartFoodPrice] = useState(0);
  const [cartFoodId, setCartFoodId] = useState();
  const [cartFoodTotalPrice, setCartFoodTotalPrice] = useState(0);
  const [priceForCalculations, setPriceForCalculations] = useState(0);
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [deliveryTo, setDeliveryTo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [foodDeliveryCategory, setFoodDeliveryCategory] = useState("");

  const navigate = useNavigate();

  {
    /**A FUNCTION TO FETCH 12 MENU FROM THE SERVER */
  }
  const fetchMenus = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/fetchRandomMenus/1`
      )
      .then((response) => {
        setMenus(response.data);
        setIsLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Set loading to false in case of an error
      });
  };

  {
    /**A FUNCTION TO FETCH ALL MENU FROM THE SERVER */
  }
  let apiEndpoint = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/fetchWebsiteAllMenus/1`;

  /**FETCH CATEGORIES */
  const fetchCategories = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/distinctCategories`
      )
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  /**A FUNCTION TO FETCH ALL MENU FROM THE SERVER   WHEN THE VIEW ALL PRODUCT BUTTON IS CLICKED*/
  const fetchAllMenusFunction = () => {
    localStorage.setItem("source", "products");
    navigate("/allProducts");
  };
  const fetchAllMenusFunctionByCategory = (id) => {
    localStorage.setItem("source", "category");
    localStorage.setItem("categoryId", id);
    navigate("/allProducts");
  };

  /**A FUNCTION TO FETCH ALL MENU FROM THE SERVER   BASED ON THE SEARCH BY PRODUCT NAME*/

  {
    /**A FUNCTION TO CHANGE THE BG COLOR OF THE APP BAR */
  }
  useEffect(() => {
    fetchMenus();
    fetchCategories();
    // Add scroll event listener to change background color on scroll
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  {
    /** STYLES FOR THE APP BAR */
  }

  {
    /** POSITION FOR THE POPUP MODAL */
  }
  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };

  {
    /**THIS FUNCTION EXECUTE WHEN THE ADD TO CART ICON IS CLICKED */
  }
  const addToCart = (
    foodId,
    foodName,
    foodPrice,
    foodDescription,
    foodImage,
    brandName
  ) => {
    localStorage.setItem("cartFoodId", foodId);
    localStorage.setItem("cartFoodName", foodName);
    localStorage.setItem("cartFoodPrice", foodPrice);
    localStorage.setItem("cartFoodDescription", foodDescription);
    localStorage.setItem("cartFoodImage", foodImage);
    localStorage.setItem("priceForCalculations", foodPrice);
    localStorage.setItem("brandName", brandName);
    navigate("/orderNow");
  };

  {
    /**THIS FUNCTION EXECUTE WHEN THE + BUTTON IS CLICKED */
  }

  {
    /**THIS FUNCTION EXECUTE WHEN THE +/- BUTTON IS CLICKED  */
  }
  useEffect(() => {
    setCartFoodTotalPrice(priceForCalculations * quantity);
  }, [quantity, priceForCalculations]);

  {
    /**LOGIN FORM DATA */
  }
  const [formData, setFormData] = useState({
    telephone: "",
    password: "",
  });

  {
    /**ORDER NOW */
  }

  {
    /**A FUNCTION TO SEND ORDER DATA TO THE SERVER */
  }
  const placeOrder = async () => {
    const newOrderDate = new Date();

    const formattedNewOrderDate = new Intl.DateTimeFormat("en", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(newOrderDate);

    // Remove colons from the formattedNewOrderDate
    const timeWithoutColons = formattedNewOrderDate.replace(/:/g, "");

    // Prepare the order data to send to the API
    const orderData = {
      /* company: { id: localStorage.getItem(
        "foodDeliveryCompanyId"
      ) },*/
      foodDeliveryCustomer: { id: customerId },
      foodDeliveryMenu: { id: cartFoodId },
      orderNumber: customerId + timeWithoutColons,
      quantity: quantity,
      //comment: message.toString(),
      deliveryTo: deliveryTo,
      price: cartFoodPrice,
      total: cartFoodTotalPrice,
      source: "Web",
    };

    // Send the order data to the API endpoint
    try {
      const response = await fetch(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Failed to place the order. Please try again later.",
            closable: true,
          },
        ]);
      }

      msgs.current.show([
        {
          sticky: false,
          severity: "success",
          summary: "Success",
          detail: "Order placed successfully!.",
          closable: true,
        },
      ]);
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: error,
          closable: true,
        },
      ]);
    }
  };

  {
    /**EXECUTE THIS FUCTION WHEN THE CUSTOMER CLICK ON THE ORDER NOW BUTTON */
  }
  const orderNow = (e) => {
    e.preventDefault();
    if (customerId == "") {
      setCustomerLoginVisible(true);
    } else {
      //placeOrder();
      initializePayment(onSuccess, onClose);
    }
  };

  {
    /** NAVIGATE TO THE NEXT TAB WHEN THE SIGN UP LINK IS CLIKED */
  }

  {
    /**PAYMENT GATEWAY */
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: "nyarkodesjr@gmail.com",
    amount: cartFoodTotalPrice * 100,
    currency: "GHS",
    publicKey: "pk_test_1935bae8273f7706297cad266a58766948183d33",
    //publicKey: "pk_live_e3e0304574b1cbd7c7ec0acae6deb6018f508b05",
  };
  const initializePayment = usePaystackPayment(config);

  // SUCCESS FUNCTION FROM ;
  const onSuccess = (reference) => {
    console.log(reference);
    console.log("order placed successfully");
    setVisible(false);
    placeOrder();
  };

  // CLOSE FUNCTION FROM PAYSTACK
  const onClose = () => {
    console.log("closed");
  };

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };

  return (
    <div>
      {/** APP BAR */}
      <Header />

      {/**HOMEPAGE WITH BG IMAGE */}
      <div className="websiteBgImage">
        <div
          className="relative isolate px-6 pt-14 lg:px-8"
          style={{ marginTop: "-80px" }}
        >
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center">
              <h1
                className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl "
                style={{ color: "white" }}
                data-aos="fade-up"
              >
                <span className="myTitle">
                  {" "}
                  Your One-Stop Shop for Fragrance, Baby Care, and Groceries!
                </span>

                <div
                  className="mt-10"
                  style={{ textAlign: "center", marginBottom: "20px" }}
                >
                  <Button
                    raised
                    variant="contained"
                    style={{ backgroundColor: "#ff007f", border: "none" }}
                    onClick={fetchAllMenusFunction}
                    label={"View All Products "}
                    data-aos="fade-up"
                    size="small"
                  />
                </div>
                <div className="followUsContainer">
                  <div
                    className="followUsText"
                    style={{
                      fontSize: "20px",
                      marginTop: "20px",
                      fontFamily: "monospace",
                    }}
                  >
                    Join us
                  </div>
                  <div
                    className="iconContainer"
                    style={{ cursor: "pointer", marginTop: "-10px" }}
                  >
                    <Facebook
                      onClick={() => {
                        window.open(
                          "https://web.facebook.com/profile.php?id=100088279560422",
                          "_blank"
                        );
                      }}
                    />{" "}
                    <Instagram
                      onClick={() => {
                        window.open(
                          "https://www.instagram.com/countclubwholesale_gh/",
                          "_blank"
                        );
                      }}
                    />{" "}
                    <WhatsApp
                      onClick={() => {
                        window.open("https://wa.me/+233550270851", "_blank");
                      }}
                    />
                  </div>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/** MENU */}
      <div className="foodDeliveryWebsiteContainer" id="menu">
        <div className="foodDeliveryWebsiteColumn1">
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <h2
              style={{
                fontWeight: "bold",
                fontSize: "30px",

                color: "#373838",
              }}
              data-aos="fade-up"
              className="myTitle"
            >
              Products
            </h2>
            <p data-aos="fade-up">A Special Products For Our Customers</p>
          </div>

          <div>
            {isLoading ? (
              // Display skeleton or loading indicator while data is fetching
              <div
                className="skeleton-container"
                style={{ color: "red", textAlign: "center" }}
              >
                {/* Your skeleton loading component/style goes here */}
                <span>Loading... </span>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {/* Skeleton Loader Item */}
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                </div>
              </div>
            ) : (
              // Display menus once data is loaded
              <div class="grid-container1">
                {menus.map((menu) => (
                  <div
                    className="container44"j
                    onClick={() =>
                      addToCart(
                        menu.id,
                        menu.foodName,
                        menu.price,
                        menu.foodDescription,
                        menu.filename,
                        menu.brand.brandName
                      )
                    }
                    data-aos="fade-up"
                  >
                    <div class="grid-item1">
                      {" "}
                      <div className="image-container">
                        <img
                          src={require(`../../../uploadImages/${menu.filename}`)}
                          alt="Image"
                          className="zoomable-image1"
                        />
                        <div
                          className="overlay-text"
                          style={{ padding: "5px", marginBottom: "15px" }}
                        >
                          {menu.foodName}
                        </div>{" "}
                      </div>
                      <div className="text-container">
                        <div className="product-details">
                          <div className="product-price">
                            <i
                              className="pi pi-tags"
                              style={{ color: "green" }}
                            />
                            <p>GHS {menu.price}</p>
                          </div>
                          {/*<div className="product-rating">
                            <Rating
                              name="read-only"
                              value={menu.review}
                              readOnly
                            />
                          </div>*/}
                          <div className="product-actions">
                            <Tooltip title="Order now">
                              <IconButton>
                                <i
                                  className="pi pi-shopping-cart"
                                  style={{ color: "green" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/** VIEW ALL BUTTON */}
      <div>
        {isLoading ? (
          <span></span>
        ) : (
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Button
              rounded
              raised
              variant="contained"
              icon="pi pi-external-link"
              style={{ backgroundColor: "#ff007f", border: "none" }}
              onClick={fetchAllMenusFunction}
              label={"View All Products "}
              data-aos="fade-up"
              size="small"
            />
          </div>
        )}
      </div>

      {/**CATEGORIES */}
      <div className="bg-gray-100">
        <div className=" px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32">
            <div className="categoryColumn">
              <div className="categoryColumn1">
                <h2
                  className="text-2xl font-bold text-gray-900"
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ color: "#ff007f" }}>{categories.length}</span>{" "}
                  <span className="myTitle"> Categories</span>
                </h2>
              </div>

              <div className="categoryColumn2">
                {/* Food Category Dropdown */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Select</InputLabel>
                  <Select
                    value={foodDeliveryCategory}
                    onChange={(e) => setFoodDeliveryCategory(e.target.value)}
                    label="Category"
                    style={{ border: "none" }}
                    filter
                  >
                    {categories.map((category) => (
                      <MenuItem
                        key={category}
                        value={category}
                        onClick={() =>
                          fetchAllMenusFunctionByCategory(category.id)
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            src={require(`../../../categoryUploadImages/${category.filename}`)}
                            className=""
                            style={{
                              width: "25px",
                              height: "25px",
                              margin: "10px",
                            }}
                          />
                          <span style={{ textTransform: "lowercase" }}>
                            {" "}
                            {category.foodCategory}
                          </span>
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="responsiveCategory">
              <div className="mt-6 space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0 sm:grid sm:grid-cols-2">
                {categories.map((category) => (
                  <div
                    key={category.id}
                    className="group relative"
                    data-aos="fade-up"
                  >
                    <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64">
                      <img
                        src={require(`../../../categoryUploadImages/${category.filename}`)}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                    <h3 className="mt-5 text-sm text-gray-500 mb-6">
                      <a
                        onClick={() =>
                          fetchAllMenusFunctionByCategory(category.id)
                        }
                        style={{
                          backgroundColor: "#ff007f",
                          padding: "10px",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "10px",
                          borderRadius: "4px",
                        }}
                      >
                        <span className="absolute inset-0" />
                        {category.foodCategory}
                      </a>
                    </h3>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**TESTIMONIES*/}
      <Testimonies />

      {/**PAYMENT */}
      <Payment />
      {/**SCROLL TO TOP */}

      {scrolling && (
        <div className="websiteScrollTop" style={{ visibility: "hidden" }}>
          <Tooltip title="Scroll Top">
            <Button
              raised
              rounded
              icon="pi pi-chevron-up"
              style={{ backgroundColor: "gold", border: "none" }}
              aria-label="Notification"
              onClick={scrollToTop}
            />
          </Tooltip>{" "}
        </div>
      )}

      <Contact />

      {/**FOOTER */}
      <footer className="footer">
        <Footer />
      </footer>

      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>

      {/** MESSAGE MODAL */}
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
    </div>
  );
}

export default FoodDeliveryWebsite;
