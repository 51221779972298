import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Messages } from "primereact/messages";
import axios from "axios";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Dialog } from "primereact/dialog";
import ImageGallery from "../../../ImageGallery";
import ImageGalleryCategory from "../../../ImageGalleryCategory";

function AddCategory() {
  const msgs = useRef(null);
  const [imageGalleryVisibility, setImageGalleryVisibility] = useState(false);
  const [foodCategory, setFoodCategory] = useState("");
  const [company, setCompany] = useState(
    localStorage.getItem("foodDeliveryCompanyId")
  );
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileSelect = (e) => {
    setSelectedFile(e.files[0]);
  };

  const addCategory = async (event) => {
    //event.preventDefault();
    console.log("Add category button is pressed. Category:", foodCategory);
    const imageNameCategory = localStorage.getItem("imageNameCategory");

    if (imageNameCategory === null) {
      // Display an alert if the value is null
      alert("Please select image for the category !!");
    }
    else{
      try {
        const formData = {
          foodCategory,
          filename:localStorage.getItem("imageNameCategory"),
          company: { id: company },
        };
        const response = await axios.post(
          `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/category`,
          formData
        );
        if (response.status >= 200) {
          setFoodCategory("");
          setSelectedFile("");
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Category added successfully.",
              closable: false,
            },
          ]);
        } else {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Failed to register vendor.",
              closable: false,
            },
          ]);
        }
      } catch (error) {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: error.response.data,
            closable: false,
          },
        ]);
      }
    }
    
  };

  return (
    <div>
      {" "}
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>      <div className="text-center flex">
  <div className="mx-auto">
    {localStorage.getItem("imageNameCategory") ? (
      <img
        alt="Customer"
        src={require(`../../../categoryUploadImages/${localStorage.getItem("imageNameCategory")}`)}
        className="w-16 h-16 rounded-full shadow-md mb-4"
      />
    ) : (
      <p>No image available</p>
    )}
  </div>
</div>
      <form className="form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Category"
          name="category"
          value={foodCategory}
          autoComplete="category"
          onChange={(e) => setFoodCategory(e.target.value)}
        />
        <Grid item xs={12} md={12}>
          <div className="card">
            <Button
              variant="contained"
              style={{ width: "100%" }}
              endIcon={<AddAPhotoIcon />}
              onClick={()=>setImageGalleryVisibility(true)}
            >
              Upload Image
            </Button>
            {selectedFile && (
              <div>
                <p>Selected File: {selectedFile.name}</p>
              </div>
            )}
          </div>
        </Grid>

        <Button
          fullWidth
          variant="contained"
          color="success"
          className="submit"
          onClick={addCategory}
          style={{ marginTop: "15px" }}
        >
          Add Category 
        </Button>
      </form>
      <Dialog
        header="Choose Image"
        visible={imageGalleryVisibility}
        style={{ width: "50vw" }}
        onHide={() => setImageGalleryVisibility(false)}
      >
        <ImageGalleryCategory />
      </Dialog>
    </div>
  );
}

export default AddCategory;
