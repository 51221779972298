import React, { useEffect, useRef, useState } from "react";
import { Button, IconButton, Rating, Tooltip, Typography } from "@mui/material";
import { Delete, EditNote, FavoriteBorder, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EditMenu from "./EditMenu";
import axios from "axios";
import { Messages } from "primereact/messages";
import Container from "../Container";
import AddMenu from "./AddMenu";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import CreateIcon from "@mui/icons-material/Create";
import AOS from "aos";

function Menus() {
  const msgs = useRef(null);
  const [menus, setMenus] = useState([]);
  const [editMenuDialog, setEditMenuDialog] = useState(false);
  const [addMenuDialog, setAddMenuDialog] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [clickedMenu, setClickedMenu] = useState(null);
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const fetchMenus = () => {
    axios
      .get(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menus/1`
      )
      .then((response) => {
        setMenus(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchMenus();
    AOS.init({ duration: 1000 });
  }, []);

  const deleteMenu = (id) => {
    axios
      .delete(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/menu/${id}`
      )
      .then((response) => {
        if (response.status >= 204) {
          msgs.current.show([
            {
              sticky: false,
              severity: "success",
              summary: "Success",
              detail: "Menu deleted successfully.",
              closable: false,
            },
          ]);

          fetchMenus();
        } else {
          msgs.current.show([
            {
              sticky: false,
              severity: "error",
              summary: "Error",
              detail: "Failed to delete menu.",
              closable: false,
            },
          ]);
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
      });
  };

  const addMenu=()=>{
    setAddMenuDialog(true);
  }

  const editMenu = (
    id,
    foodName,
    foodDescription,
    foodDeliveryCategory,
    review,
    status,
    price,
    filename
  ) => {
    console.log(id);
    console.log(foodName);
    console.log(foodDescription);
    console.log(review);
    console.log(status);
    console.log(price);
    console.log(filename);

    localStorage.setItem("menuId", id);
    localStorage.setItem("foodName", foodName);
    localStorage.setItem("foodDescription", foodDescription);
    localStorage.setItem("foodCategory", foodDeliveryCategory);
    localStorage.setItem("review", review);
    localStorage.setItem("status", status);
    localStorage.setItem("price", price);
    localStorage.setItem("imageName", filename);

    setEditMenuDialog(true);
  };
  return (
    <div>
      <Container>
        <div className="addMenuContainer">
          <div className="addMenuColumn1">
            {" "}
            <div className="messageContainer">
              <div className="card flex justify-content-center">
                <Messages ref={msgs} />
              </div>
            </div>
            {isLoading ? (
              // Display skeleton or loading indicator while data is fetching
              <div
                className="skeleton-container"
                style={{ color: "red", textAlign: "center" }}
              >
                {/* Your skeleton loading component/style goes here */}
                
              </div>
            ) : (
            <div style={{ display: "flex", justifyContent: "space-between",marginBottom:"10px" }}>
              <Typography
                variant="p"
                style={{ marginBottom: "5px", marginTop: "-10px" }}
              >
                List Of Items{" "}
                <Badge
                  style={{ marginLeft: "3px" }}
                  value={menus.length}
                  severity="success"
                ></Badge>
              </Typography>
              <Button color="success"  variant="contained" onClick={addMenu} >Add Product</Button>
            </div>
            )}
            {isLoading ? (
              // Display skeleton or loading indicator while data is fetching
              <div
                className="skeleton-container"
                style={{ color: "red", textAlign: "center" }}
              >
                {/* Your skeleton loading component/style goes here */}
                <span>Loading... </span>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {/* Skeleton Loader Item */}
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                  <div className="skeleton-item"></div>
                </div>
              </div>
            ) : (
            <div className="customerContainer">
              {menus.map((menu) => (
                <div
                  className="customerBox"
                  onMouseEnter={() => setHoveredMenu(menu.id)}
                  onMouseLeave={() => setHoveredMenu(null)}
                  onClick={() => setClickedMenu(menu.id)}  data-aos="zoom-in"
                >
                  <div
                    className="customerColContaier"
                    style={{ height: "120px", padding: 5 }}
                  >
                    <div className="customerCol1">
                      <img
                        alt="Customer"
                        src={require(`../../../uploadImages/${menu.filename}`)}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    <div className="customerCol2">
                      <div>
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {menu.foodName}
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: "12px", marginLeft: "2px" }}>
                          <Rating
                            name="read-only"
                            value={menu.review}
                            readOnly
                          />
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: "12px", marginLeft: "2px" }}>
                          {menu.status}
                        </span>
                        <span
                          style={{
                            fontSize: "10px",
                            marginLeft: "4px",
                            fontWeight: "bold",
                          }}
                        >
                          {currency}
                          {menu.price.toLocaleString("en-GH", {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                    {hoveredMenu === menu.id && (
                      <div className="overlayIcons">
                        <IconButton
                          aria-controls="menu"
                          aria-haspopup="true"
                          onClick={() =>
                            editMenu(
                              menu.id,
                              menu.foodName,
                              menu.foodDescription,
                              menu.foodCategory,
                              menu.review,
                              menu.status,
                              menu.price,
                              menu.filename
                            )
                          }
                          color="inherit"
                        >
                          <Tooltip placement="top" title="Edit">
                            <CreateIcon style={{ color: "gray" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() => deleteMenu(menu.id)}
                          color="inherit"
                        >
                          <Tooltip placement="bottom" title="Delete">
                            <Delete style={{ color: "grey" }} />
                          </Tooltip>
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            )}
          </div>
         {/** <div className="addMenuColumn2">
            <AddMenu fetchMenus={fetchMenus} />
          </div>*/} 
        </div>

       
      </Container> <Dialog
          header="Edit Product"
          visible={editMenuDialog}
          style={{ width: "70vw",zIndex:"999999999999" }}
          onHide={() => setEditMenuDialog(false)}
        >
          <EditMenu fetchMenus={fetchMenus} />
        </Dialog> <Dialog
          header=""
          visible={addMenuDialog}
          style={{ width: "70vw",zIndex:"999999999999" }}
          onHide={() => setAddMenuDialog(false)}
        >
           <AddMenu fetchMenus={fetchMenus} />
        </Dialog>
    </div>
  );
}

export default Menus;
