import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Messages } from "primereact/messages";

function CustomerLogin() {
  const [loading, setLoading] = useState(false);
  const msgs = useRef(null);
  const [customerId, setCustomerId] = useState(
    localStorage.getItem("loginCustomerId")
  );
  const [customerFirstname, setCustomerFirstname] = useState(
    localStorage.getItem("loginCustomerFirstname")
  );
  const [customerLastname, setCustomerLastname] = useState(
    localStorage.getItem("loginCustomerLastname")
  );

  const login = async () => {
    console.log(formData);
    console.log("the telephone is ", formData.telephone);
    console.log("the password is ", formData.password);
    try {
      // Perform the API call to retrieve user data based on telephone
      const response = await fetch(
        `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/customerByTelephone/1/${formData.telephone}`
      );

      if (!response.ok) {
        throw new Error("Invalid response from the server");
      }
      const customerData = await response.json();

      if (customerData && customerData.password === formData.password) {
        localStorage.setItem("loginCustomerId", customerData.id);
        localStorage.setItem("loginCustomerFirstname", customerData.firstname);
        localStorage.setItem("loginCustomerLastname", customerData.lastname);
        setCustomerId(customerData.id);
        setCustomerFirstname(customerData.firstname);
        setCustomerLastname(customerData.lastname);
        /*formData.telephone = "";
        formData.password = "";*/
        msgs.current.show([
          {
            sticky: false,
            severity: "success",
            summary: "Success",
            detail: "login successfully.",
            closable: false,
          },
        ]);
      } else {
        msgs.current.show([
          {
            sticky: false,
            severity: "error",
            summary: "Error",
            detail: "Incorrect password.",
            closable: false,
          },
        ]);
      }
    } catch (error) {
      msgs.current.show([
        {
          sticky: false,
          severity: "error",
          summary: "Error",
          detail: "Incorrect telephone or password. Please try again later",
          closable: false,
        },
      ]);
    }
  };
  const [formData, setFormData] = useState({
    telephone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      login();
    }, 2000);
  };
  const logout = () => {
    setCustomerId("");
    setCustomerFirstname("");
    setCustomerLastname("");
    localStorage.removeItem("loginCustomerId");
    localStorage.removeItem("loginCustomerFirstname");
    localStorage.removeItem("loginCustomerLastname");
    formData.telephone = "";
    formData.password = "";
    msgs.current.show([
      {
        sticky: false,
        severity: "success",
        summary: "Success",
        detail: "Logout successfully.",
        closable: false,
      },
    ]);
  };

  return (
    <div>
      <div className="foodDeliveryWebsiteCartContainer">
        <div>
          {customerId && (
            <h1>
              You have logged in as{" "}
              <span style={{ fontWeight: "bold" }}>
                {customerFirstname} {customerLastname}
              </span>{" "}
              <span
                style={{ color: "royalblue", cursor: "pointer" }}
                onClick={logout}
              >
                click here to logout
              </span>{" "}
            </h1>
          )}
          <div
            style={{
              backgroundColor: "whitesmoke",
              width: "100%",
              padding: "15px",
            }}
          >
            <p style={{ color: "gray" }}>Please fill the form</p>
          </div>
          <div
            className="customer-registration-form"
            style={{ padding: "20px" }}
          >
            <form onSubmit={handleLogin}>
              <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                <span className="p-inputgroup-addon">
                  <i className="pi pi-phone"></i>
                </span>
                <InputText
                  type="tel"
                  placeholder="Telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="p-inputgroup" style={{ marginBottom: "15px" }}>
                <span className="p-inputgroup-addon">
                  <i className="pi pi-lock"></i>
                </span>
                <InputText
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              {loading ? (
                <div
                  className="card"
                  style={{ position: "absolute", zIndex: "99" }}
                >
                  {" "}
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    strokeWidth="8"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  label="Login"
                  type="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#ff007f",
                    border: "none",
                  }}
                />
              )}
            </form>
          </div>
        </div>
      </div>
      {/** MESSAGE MODAL */}
      <div className="messageContainer">
        <div className="card flex justify-content-center">
          <Messages ref={msgs} />
        </div>
      </div>
    </div>
  );
}

export default CustomerLogin;
