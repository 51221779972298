import React, { useEffect, useState } from "react";
import Container from "../Container";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  colors,
  Typography,
  TablePagination,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Badge } from "primereact/badge";

function RiderTrips() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [orders, setOrders] = useState([]);
  const [id, setId] = useState(localStorage.getItem("riderId"));
  const [riderName, setRiderName] = useState(localStorage.getItem("riderName"));
  const [riderFilename, setRiderFilename] = useState(
    localStorage.getItem("riderFilename")
  );
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );
  const [company, setCompany] = useState("1");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const navigate = useNavigate();
  const moveToBack = () => {
    navigate("/riders");
  };
  const fetchOrders = () => {
    console.log("the rider id is ", localStorage.getItem("riderId"));
    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/rider/orders/${id}`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const retrieveRiderTripByDate = (date) => {
    setSelectedDate(date.value);
    if (date.value) {
      const formattedDate = new Date(date.value).toISOString().split("T")[0];
      console.log(formattedDate);
      axios

        .get(
          `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/riderOrderDate/${company}/${id}?orderedDate=${formattedDate}`
        )
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  return (
    <div>
      <Container>
        <Grid container spacing={2} columns={16} sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <div style={{ display: "flex" }}>
              <Avatar
                alt="foodDeliveryCustomer Image"
                src={`../../../uploadImages/${riderFilename}`}
                style={{
                  width: "25px",
                  height: "25px",
                  marginRight: "10px",
                }}
              />
              <Typography variant="h5" style={{ fontSize: "15px" }}>
                {riderName} Trips{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  <Badge
                    style={{ marginLeft: "3px" }}
                    value={orders.length}
                    severity="success"
                  ></Badge>
                </span>
              </Typography>
              <Tooltip title="Move back">
                <IconButton
                  style={{ marginTop: "-10px" }}
                  aria-label="Move baack"
                  color="secondary"
                  onClick={moveToBack}
                >
                  <ArrowCircleLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="date-picker" style={{ textAlign: "end" }}>
              <Calendar
                placeholder="Search by Date"
                dateFormat="mm/dd/yy"
                selected={selectedDate}
                onChange={retrieveRiderTripByDate}
              />
            </div>
          </Grid>
        </Grid>
        <div>
          <TableContainer component={Paper} className="responsive-table">
            <Table>
              <TableHead style={{ backgroundColor: "whitesmoke" }}>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Order Number</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Grand Total</TableCell>
                  <TableCell>Delivery To</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Time Ordered</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>
                        <Avatar
                          alt="foodDeliveryCustomer Image"
                          src={`../../../uploadImages/${order.foodDeliveryCustomer.filename}`}
                          style={{
                            width: "25px",
                            height: "25px",
                            marginRight: "10px",
                          }}
                        />
                        {order.foodDeliveryCustomer.firstname} {order.foodDeliveryCustomer.lastname}
                      </TableCell>
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell>{order.foodDeliveryMenu.foodName}</TableCell>
                      <TableCell>
                        <img
                          alt="foodDeliveryCustomer"
                          src={`../../../uploadImages/${order.foodDeliveryMenu.filename}`}
                          style={{
                            width: "100px",
                            height: "60px",
                            borderRadius: "10px",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {currency}
                        {order.price.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>{order.quantity}</TableCell>
                      <TableCell>
                        {currency}
                        {order.total.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>{order.deliveryTo}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>
                        {new Date(order.orderedDate).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[4, 8, 12]}
            component="div"
            count={orders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default RiderTrips;
