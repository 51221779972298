import React, { useEffect, useState } from "react";
import Container from "../Container";
import { Phone } from "@mui/icons-material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import OrderStep from "./OrderStep";
import ProcessingStep from "./ProcessingStep";
import ShippedStep from "./ShippedStep";
import DeliveredStep from "./DeliveredStep";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ViewOrder() {
 

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);

 
  const [id, setId] = useState(localStorage.getItem("orderId"));
  const [orderNumber, setOrderNumber] = useState("");
  const [orderFilename, setOrderFilename] = useState("empty.jpg");
  const [foodName, setFoodName] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [message, setMessage] = useState("");
  const [timeOrdered, setTimeOrdered] = useState("");
  const [customerFirstname, setCustomerFirstname] = useState("");
  const [customerLastname, setCustomerLastname] = useState("");
  const [customerTelephone, setCustomerTelephone] = useState("");
  const [customerFilename, setCustomerFilename] = useState("");
  const [stepCount, setStepCount] = useState("");
  const [currency, setCurrency] = useState(
    localStorage.getItem("companyCurrency")
  );

  const fetchOrderDetails=()=>{

    // Define the URL with the id parameter
    const url = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders/${id}`;

    // Make an HTTP GET request to fetch vendor data
    axios
      .get(url)
      .then((response) => {
        const orderData = response.data;

        setOrderNumber(orderData.orderNumber);
        setOrderFilename(orderData.foodDeliveryMenu.filename);
        setFoodName(orderData.foodDeliveryMenu.foodName);
        setUnitPrice(orderData.price);
        setQuantity(orderData.quantity);
        setGrandTotal(orderData.total);
        setDeliveryTo(orderData.deliveryTo);
        setMessage(orderData.comment);
        setTimeOrdered(orderData.newOrderDate);
        setCustomerFirstname(orderData.foodDeliveryCustomer.firstname);
        setCustomerLastname(orderData.foodDeliveryCustomer.lastname);
        setCustomerTelephone(orderData.foodDeliveryCustomer.telephone);
        setCustomerFilename(orderData.foodDeliveryCustomer.filename);
        setStepCount(orderData.stepCount);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching order data:", error);
      });
  }
  useEffect(() => {
    fetchOrderDetails();
  }, [id]);
  const steps = [
    {
      label: "Ordered",
      description: <OrderStep fetchOrderDetails={fetchOrderDetails} />,
    },
    {
      label: "Processing",
      description: <ProcessingStep  fetchOrderDetails={fetchOrderDetails} />,
    },
    {
      label: "Shipped",
      description: <ShippedStep  fetchOrderDetails={fetchOrderDetails} />,
    },
    {
      label: "Delivered",
      description: <DeliveredStep  fetchOrderDetails={fetchOrderDetails} />,
    },
  ]; 
  const moveToBack = () => {
    navigate("/orders");
  };
  return (
    <div>
      <Container >
          <Grid item xs={4}>
            <Typography variant="h5" style={{ marginBottom: "20px" }}>
              Tracking Order 
                <Tooltip title="Move back">
                  <IconButton
                    style={{ marginTop: "-10px" }}
                    aria-label="Move baack"
                    color="success"
                    onClick={moveToBack}
                  >
                    <ArrowCircleLeftIcon />
                  </IconButton>
                </Tooltip>
            </Typography>
          </Grid>
          <div className="viewOrderContainer">
            <div className="viewOrderColumn1">
              <img
                alt="foodDeliveryCustomer"
                src={require(`../../../uploadImages/${orderFilename}`)}
                style={{
                  width: "100%",
                  height: "300px",
                  borderRadius: "10px",
                }}
              />

              <TableContainer component={Paper} style={{marginTop:"10px"}}>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Customer
                      </TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          <Avatar
                            alt="Customer Image"
                            src={`../../../uploadImages/${customerFilename}`}
                            style={{
                              width: "25px",
                              height: "25px",
                              marginRight: "10px",
                            }}
                          />
                          {customerFirstname} {customerLastname}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Telephone
                      </TableCell>
                      <TableCell>
                        {" "}
                        <div style={{ display: "flex" }}>
                          <div>
                            <Phone
                              className="icon"
                              style={{ fontSize: "22px", marginRight: "10px" }}
                            />
                          </div>
                          <div>{customerTelephone}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="viewOrderColumn2">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Order No
                      </TableCell>
                      <TableCell>{orderNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Food Name
                      </TableCell>
                      <TableCell>{foodName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Unit Price
                      </TableCell>
                      <TableCell>
                        {" "}
                        {currency}
                        {unitPrice.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Quantity
                      </TableCell>
                      <TableCell>{quantity}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Grand Total
                      </TableCell>
                      <TableCell>
                        {" "}
                        {currency}
                        {grandTotal.toLocaleString("en-GH", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Delivery To
                      </TableCell>
                      <TableCell>{deliveryTo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Message
                      </TableCell>
                      <TableCell>{message}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Time Ordered
                      </TableCell>
                      <TableCell>
                        {new Date(timeOrdered).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="viewOrderColumn3">
              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={stepCount} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          index === 3 ? (
                            <Typography variant="caption">Last step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
          </div>
      </Container>
    </div>
  );
}

export default ViewOrder;
