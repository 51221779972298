import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import axios from "axios";

function FirstChart() {
  //const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    axios
      .get(`https://reminiscent-swim-production.up.railway.app/api/foodDelivery/monthlyOrdersChart/${localStorage.getItem("foodDeliveryCompanyId")}`)
      .then((response) => {
        const monthlyOrdersCharts = response.data;

        // Map the response data to your desired format
        const formattedData = {
          labels: monthlyOrdersCharts.map((item) => item.foodName),
          datasets: [
            /*{
              label: "Web",
              backgroundColor: documentStyle.getPropertyValue("--blue-500"),
              borderColor: documentStyle.getPropertyValue("--blue-500"),
              data: monthlyOrdersCharts.map((item) => item.web),
            },*/
            {
              label: "Amount Accumulated ",
              backgroundColor: documentStyle.getPropertyValue("--green-500"),
              borderColor: documentStyle.getPropertyValue("--green-500"),
              data: monthlyOrdersCharts.map((item) => item.amountAccumulated),
            },
           /* {
              label: "Mobile",
              backgroundColor: documentStyle.getPropertyValue("--orange-500"),
              borderColor: documentStyle.getPropertyValue("--orange-500"),
              data: monthlyOrdersCharts.map((item) => item.mobile),
            },*/
          ],
        };

        setData(formattedData); // Set the data obtained from the API response
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    //setChartData(data);
    setChartOptions(options);
  }, []);
  return (
    <div className="card" style={{ height: "100%" }}>
      <Chart type="bar" data={data} options={chartOptions} height="100%" />
    </div>
  );
}

export default FirstChart;
