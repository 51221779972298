import React, { useEffect } from "react";
import Header from "./Header";
import BottomNavBAr from "./BottomNavBar";
import AOS from "aos";

function Testimonies() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

    useEffect(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
      }, []);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#ff007f",
          padding: "10px",
          color: "white",
          position: "fixed",
          width: "100%",
          zIndex: "999",
          height: "60px",
        }}
      ></div>
      <Header />
      <div className="whyChooseUsContainer" id="about">
        <div
          style={{
            textAlign: "center",
            color: "#373838",
            marginBottom: "10px",
            paddingTop: "60px",
          }}
        >
          <h1
            style={{ fontWeight: "bold", fontSize: "40px", marginTop: "40px" }}
            data-aos="fade-up"
            className="myTitle"
          >
            Our Testimonies
          </h1>
          <p data-aos="fade-up">Clients' Most Popular Choise</p>
        </div>
      </div>
      <div class="testimonial-container" style={{marginBottom:"60px"}}>
        <div class="testimonial"  data-aos="fade-up">
          <img src={require("../../images/t1.jpg")} alt="Testimonial 1" />
          <h4 className="myTitle">Suzzy</h4>
          <p>
            I am thoroughly impressed with CountClub Wholesale's commitment to a
            seamless online shopping experience. The website's design is
            intuitive, and the quick checkout process ensures that I can get my
            favorite products delivered promptly. A fantastic online shopping
            destination!
          </p>
        </div>
        <div class="testimonial"  data-aos="fade-up">
          <img src={require("../../images/t2.jpg")} alt="Testimonial 2" />
          <h4 className="myTitle">Ebenezer</h4>
          <p>
            As a culinary enthusiast, CountClub Wholesale has exceeded my
            expectations. The commitment to quality and variety in their product
            offerings is evident. From fragrances to baby essentials, this store
            has become my trusted source for high-quality products.
          </p>
        </div>
        <div class="testimonial"  data-aos="fade-up">
          <img src={require("../../images/t3.jpg")} alt="Testimonial 3" />
          <h4 className="myTitle">Esther</h4>
          <p>
            Shopping at CountClub Wholesale has been a game-changer for my
            household. The convenience of finding all my family's needs, from
            fragrances to baby essentials, in one place is unparalleled. The
            curated selection and top-notch quality make it my go-to
            destination.
          </p>
        </div>
      </div>
      {/**BOTTOM NAV BAR */}
      <div className="bottomNavBar">
        <BottomNavBAr />
      </div>
    </div>
  );
}

export default Testimonies;
