import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MyReview from "./Reviews";
import {
  Avatar,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "../Container";
import axios from "axios";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

function ReviewDetails() {
  const [orderNumber, setOrderNumber] = useState("");
  const [customerFirstname, setCustomerFirstname] = useState("");
  const [customerLastname, setCustomerLastname] = useState("");
  const [customerFilename, setCustomerFilename] = useState("");
  const [foodName, setFoodName] = useState("");
  const [foodFilename, setFoodFilename] = useState("");
  const [currency, setCurrency] = useState("");

  const [unitPrice, setUnitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");
  const [foodDeliveryDispatchRider, setDispatchRider] = useState("");
  const [riderFilename, setRiderFilename] = useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const findOrderByOrderId = () => {
    const apiUrl = `https://reminiscent-swim-production.up.railway.app/api/foodDelivery/orders/${localStorage.getItem(
      "findOrderByOrderId"
    )}`;
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setOrderNumber(data.orderNumber);
        setCustomerFirstname(data.foodDeliveryCustomer.firstname);
        setCustomerLastname(data.foodDeliveryCustomer.lastname);
        setCustomerFilename(data.foodDeliveryCustomer.filename);
        setFoodName(data.foodDeliveryMenu.foodName);
        setFoodFilename(data.foodDeliveryMenu.filename);
        setUnitPrice(data.price);
        setQuantity(data.quantity);
        setGrandTotal(data.total);
        setDeliveryTo(data.deliveryTo);
        setDispatchRider(data.foodDeliveryDispatchRider.riderName);
        setCurrency(data.foodDeliveryCustomer.company.currency);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    findOrderByOrderId();
  }, []);

  return (
    <div>
      <Typography variant="p" sx={{ mb: 2, float: "right" }}>
        Customer Reviews
      </Typography>
      <div className="reviewContainer">
        <div className="reviewColumn1">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Avatar
                      alt="Food Image"
                      src={`../../../uploadImages/${customerFilename}`}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    {customerFirstname} {customerLastname}
                  </StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Order Number
                  </StyledTableCell>
                  <StyledTableCell align="right">{orderNumber}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Customer
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {customerFirstname} {customerLastname}
                  </StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Food Ordered
                  </StyledTableCell>
                  <StyledTableCell align="right">{foodName}</StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Unit Price
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {" "}
                    {currency}
                    {unitPrice.toLocaleString("en-GH", {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Qty Ordered
                  </StyledTableCell>
                  <StyledTableCell align="right">{quantity}</StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Total Price
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {currency}
                    {grandTotal.toLocaleString("en-GH", {
                      minimumFractionDigits: 2,
                    })}
                  </StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Delivered To
                  </StyledTableCell>
                  <StyledTableCell align="right">{deliveryTo}</StyledTableCell>
                </StyledTableRow>{" "}
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: "bold" }}
                  >
                    Dispatch Rider
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {foodDeliveryDispatchRider}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="reviewColumn2">
          <MyReview findOrderByOrderId={findOrderByOrderId} />
        </div>
      </div>
    </div>
  );
}

export default ReviewDetails;
